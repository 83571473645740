import { useState, useEffect, React } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography, Paper, TextField } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
import { getFinetunes, deleteFinetune, deleteExpertResource} from '../Utilities/firestoreConnector';
import { getResources } from '../Utilities/apiConnector';

function QuickSearchToolbar(props) {
  return (
    <Box
      sx={{
        p: 0.5,
        pb: 0,
      }}
    >
      <TextField
        variant="standard"
        value={props.value}
        onChange={props.onChange}
        placeholder="Search…"
        fullWidth
      />
    </Box>
  );
}

export default function FinetuneList() {
  const { orgId } = useParams();
  const navigate = useNavigate();
  const [finetunes, setFinetunes] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [selectedFinetunes, setSelectedFinetunes] = useState([]);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  useEffect(() => {
    const fetchData = async () => { setFinetunes(await getFinetunes(orgId)); };
    fetchData();
  }, [orgId]);

  const handleEdit = (row) => { navigate(`/organizations/${orgId}/finetune/${row.id}`); };
  const handleDelete = () => { setOpenDeleteDialog(true); };

  const handleDeleteConfirm = async () => {
    const experts = await getResources('expert', orgId);
    const promises = selectedFinetunes.map(f => deleteFinetune(f.id).then(() => experts.forEach(expert => { if (expert.resources?.finetunes?.includes(f.id)) { deleteExpertResource(expert.id, 'finetunes', f.id); } })));
    
    await Promise.all(promises);
    setSelectedFinetunes([]);
    setOpenDeleteDialog(false);
  };

  const handleDeleteCancel = () => { setOpenDeleteDialog(false); };

  const handleSelectionChange = (newSelection) => {
    setSelectedFinetunes(finetunes.filter(f => newSelection.includes(f.id)));
  };

  const columns = [
    { field: 'name', headerName: 'Name', flex: 1.5 },
    { field: 'role', headerName: 'Role', flex: 0.5 },
    { field: 'query', headerName: 'Content', flex: 1, renderCell: (params) => (params.row.query ? params.row.query : params.row.instructions) },
    { field: 'action', headerName: 'Action', flex: 0.5, renderCell: (params) => (<Button variant="contained" color="primary" onClick={(e) => { e.stopPropagation(); handleEdit(params.row); }}>Edit</Button>) }
  ];

  const filteredRows = finetunes.filter((row) => {
    return row.name.toLowerCase().includes(searchText.toLowerCase());
  });

  return (
    <Paper>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <Typography variant="h4" component="h1" sx={{ m: 2 }}>Finetunes List</Typography>
        {selectedFinetunes.length > 0 && <Button sx={{ m: 2 }} variant="contained" color="secondary" startIcon={<DeleteIcon />} onClick={handleDelete}>Delete</Button>}
      </Box>
      <Box sx={{ m: 2, maxWidth: '1000px',overflow: 'hidden' }}>
        <DataGrid 
          components={{ Toolbar: QuickSearchToolbar }}
          componentsProps={{ toolbar: { value: searchText, onChange: (e) => setSearchText(e.target.value) } }}
          rows={filteredRows} 
          columns={columns} 
          checkboxSelection
          onRowSelectionModelChange={handleSelectionChange}
          rowSelectionModel={selectedFinetunes.map(f => f.id)}
          getRowId={(row) => row.id}
          disableSelectionOnClick
        />
      </Box>
      <Dialog open={openDeleteDialog} onClose={handleDeleteCancel}>
        <DialogTitle>Delete File</DialogTitle>
        <DialogContent>
          <DialogContentText>Are you sure you want to delete {selectedFinetunes.length} item(s)?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteCancel} color="primary">Cancel</Button>
          <Button onClick={handleDeleteConfirm} color="primary">Delete</Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
}
