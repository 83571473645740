import React, { useState, useEffect } from 'react';
import { collection, onSnapshot, deleteDoc, doc } from 'firebase/firestore';
import { db } from '../firebase';
import { Container, Typography, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import AddOrganization from '../Components/AddOrganization' 
import ListOrganizations from '../Components/ListOrganizations';
import Divider from '@mui/material/Divider';

function Organizations() {
  return(     
    <Container>
    <Typography variant="h4" component="h1">List Organizations</Typography>
    <Divider />
    <ListOrganizations collectionName="organizations" />
    <Divider />
    <AddOrganization />
  </Container>
  );
}

export default Organizations;