import React, { useState, useEffect, useContext, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Button, Grid } from '@mui/material';
import { styled } from '@mui/system';
import ChatBubble from '../Components/ChatBubble';
import ChatInput from '../Components/ChatInput';
import { db } from '../firebase';
import { collection, query, where, onSnapshot, orderBy } from "firebase/firestore";
import { getFinetunes, creatFinetune, deleteFinetune, updateFinetune} from '../Utilities/firestoreConnector';
import { UserContext } from '../UserProvider';
import { v4 as uuidv4 } from 'uuid';
import { vectorizeText } from '../Utilities/apiConnector';
import FinetuneList from '../Components/FinetuneList'

function Chat() {
  const { orgId } = useParams();
  const { user, userData, updateUser } = useContext(UserContext);
  const navigate = useNavigate();

  const handleAddFinetune = async (type) => {
    const newFinetune = {
      name: `New ${type}`,
      role: 'user',
      owner: orgId,
      type
    }
    const id = await creatFinetune(newFinetune);
    navigate(`/organizations/${orgId}/finetune/${id}`);

    // fileInputRef.current.click();
  };

  return (
    <Grid container sx={{ p: 3 }} spacing={2} direction="column">
    {userData && userData.currentOrganization && (
      <Box>
        <Grid>
          <FinetuneList />
        </Grid>
        <Grid sx={{ mt: 3 }}>
          <Button variant="contained" color="primary" onClick={e => handleAddFinetune('finetune')} sx={{ mr: 1 }}>Add QA</Button>
          <Button variant="contained" color="primary" onClick={e => handleAddFinetune('instructions')}>Add Instructions</Button>
        </Grid>
      </Box>
    )}
  </Grid>
  );
}

export default Chat;
