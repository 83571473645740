import { useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, Chip, TextField } from '@mui/material';

export default function AddResourceDialog({ resourceData, onSelected, initiallySelected, open, onClose }) {
  const [selectedResources, setSelectedResources] = useState(initiallySelected || []);
  const [searchQuery, setSearchQuery] = useState('');

  const handleToggle = (resourceId) => {
    setSelectedResources((prevSelected) =>
      prevSelected.includes(resourceId)
        ? prevSelected.filter((id) => id !== resourceId)
        : [...prevSelected, resourceId]
    );
  };

  const handleAdd = () => {
    onSelected(selectedResources);
    setSelectedResources([]);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value.toLowerCase());
  };

  return (
    <Dialog open={open} onClose={e => { setSelectedResources(initiallySelected); onClose(); }}>
      <DialogTitle>Add Resources</DialogTitle>
      <DialogContent>
        {/* Search input */}
        <TextField
          fullWidth
          margin="normal"
          variant="outlined"
          label="Search Resources"
          value={searchQuery}
          onChange={handleSearchChange}
          style={{ marginBottom: '20px' }}
        />
        {resourceData && Object.entries(resourceData.resources)
          .filter(([id, resource]) => resource[resourceData.fieldName].toLowerCase().includes(searchQuery))
          .map(([id, resource]) => (
            <Chip
              key={id}
              label={resource[resourceData.fieldName]}
              color="primary"
              variant={selectedResources.includes(resource.id) ? "default" : "outlined"}
              onClick={() => handleToggle(resource.id)}
              style={{ margin: '5px' }}
            />
          ))}
      </DialogContent>
      <DialogActions>
        <Button onClick={e => { setSelectedResources(initiallySelected); onClose(); }} color="primary" variant="contained">
          CANCEL
        </Button>
        <Button onClick={handleAdd} color="primary" variant="contained">
          SAVE
        </Button>
      </DialogActions>
    </Dialog>
  );
}