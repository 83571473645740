import { useState, useEffect } from 'react';
import { Paper, Typography, Slider, TextField, Box, Grid, Button, Select, MenuItem, Checkbox } from '@mui/material';
import PromptComposer from './PromptComposer';

export default function ExpertConfig({ data, onUpdate, onReload }) {
  const [update, setUpdate] = useState(null);
  const [name, setName] = useState("");
  const [temp, setTemp] = useState(null);
  const [topK, setTopK] = useState(null);
  const [model, setModel] = useState('gpt-4o');
  const [contextRole, setContextRole] = useState('user');
  const [useHistory, setUseHistory] = useState(false);

  useEffect(() => {
    setName(data?.name ?? "New Name");
    setTemp(data?.config?.temp ?? 0.4);
    setTopK(data?.config?.vectorDBtopK ?? 5);
    setModel(data?.config?.model ?? 'gpt-4o');
    setContextRole(data?.config?.contextRole ?? 'user');
    setUseHistory(data?.config?.useHistory ?? false);
    setUpdate(data ?? null);
  }, [data]);

  const handleNameChange = (v) => { setName(v); setUpdate({...data, name: v });  };
  const handleTempChange = (e, v) => { setTemp(v); setUpdate({...data, config: {...data.config, temp: v }});  };
  const handleTopKChange = (e, v) => { setTopK(v); setUpdate({...data, config: {...data.config, vectorDBtopK: v }});  };
  const handleModelChange = (v) => { setModel(v);  setUpdate({...data, config: {...data.config, model: v }}); };
  const handleContextRoleChange = (v) => { setContextRole(v);  setUpdate({...data, config: {...data.config, contextRole: v }}); };
  const handleUseHistory = (e, v) => { setUseHistory(v); setUpdate({...data, config: {...data.config, useHistory: v }}); };
  const handleSave = () => { onUpdate(update); };
  const handleReload = () => { onReload(); };

  const inSync = JSON.stringify(data) === JSON.stringify(update);

  return (
    <Paper sx={{ p: 2, mb : 2}}>
      <Box sx={{ p: 1 }}>
        <TextField sx={{ m: 1 }} value={name} onChange={(e) => handleNameChange(e.target.value)} label="Name"/>
        <Select sx={{ m: 1 }} value={model} onChange={(e) => handleModelChange(e.target.value)}>
          <MenuItem value="gpt-4o">GPT-4o</MenuItem>
          <MenuItem value="gpt-4">GPT-4</MenuItem>
          <MenuItem value="gpt-4-1106-preview">GPT-4 Turbo</MenuItem>
          <MenuItem value="gpt-3.5-turbo">GPT-3.5-Turbo</MenuItem>
        </Select>
        Context Role:
        <Select sx={{ m: 1 }} value={contextRole} onChange={(e) => handleContextRoleChange(e.target.value)}>
          <MenuItem value="user">User</MenuItem>
          <MenuItem value="assistant">Assistant</MenuItem>
          <MenuItem value="system">System</MenuItem>
        </Select>
      </Box>
      <Box sx={{ p: 1 }}>
      <Checkbox checked={useHistory} onChange={handleUseHistory}/> Use History
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Box style={{ marginRight: '1rem', padding: '0.5rem', textAlign: 'center' }}>
          <Typography variant="body1">
            Temperature
          </Typography>
        </Box>
        <Slider value={temp} onChange={handleTempChange} min={0} max={2} step={0.01} />
        <Paper variant="outlined" style={{ marginLeft: '1rem', padding: '0.5rem', width: '60px', textAlign: 'center' }}>
          <Typography variant="body1">
            {temp}
          </Typography>
        </Paper>
      </div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Box style={{ marginRight: '1rem', padding: '0.5rem', textAlign: 'center' }}>
          <Typography variant="body1">
            TopK
          </Typography>
        </Box>
        <Slider value={topK} onChange={handleTopKChange} min={5} max={20} step={1} />
        <Paper variant="outlined" style={{ marginLeft: '1rem', padding: '0.5rem', width: '60px', textAlign: 'center' }}>
          <Typography variant="body1">
            {topK}
          </Typography>
        </Paper>
      </div>
      </Box>
      {/* <Box sx={{ p: 1 }}>
        <Typography variant="body1">Prompt Composition:</Typography>
        <PromptComposer />
      </Box> */}
      <Box sx={{ p: 1 }}>
        <Button variant="contained" color="primary" onClick={handleSave} disabled={inSync}>Save</Button>
      </Box>
      <Box sx={{ p: 1 }}>
        <Button variant="contained" color="primary" onClick={handleReload}>Reload</Button>
      </Box>
    </Paper>
  );
  
}
