import React, { createContext, useState, useEffect } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import { doc, updateDoc, onSnapshot } from '@firebase/firestore';
import { auth, db } from './firebase';
import { setUserApiKey, setDefaultHeaders } from './Utilities/apiConnector';

export const UserContext = createContext(null);

const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);  // Loading state

  const updateUser = async (fieldsToUpdate) => {
    const userDocRef = doc(db, 'users', user.uid);
    await updateDoc(userDocRef, fieldsToUpdate);
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (authUser) => {
      if (authUser) {

        const token = await authUser.getIdToken(true);
        setDefaultHeaders(token);

        const userDocRef = doc(db, 'users', authUser.uid);
        const unsubscribeFromSnapshot = onSnapshot(userDocRef, (snapshot) => {
          const userData = snapshot.data();

          setUser(authUser);
          setUserApiKey(userData.apiKey);
          setUserData(userData);
          setLoading(false);
        });

        return () => {
          unsubscribe();
          unsubscribeFromSnapshot();
        };
      } else {
        setUser(null);
        setUserApiKey(null);
        setUserData(null);
        setLoading(false);
      }
    });

    return () => unsubscribe();
  }, [user]);

  return (
    <UserContext.Provider value={{ user, userData, setUser, updateUser, loading }}>
      {children}
    </UserContext.Provider>
  );
};

export default UserProvider;
