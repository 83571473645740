import { arrayRemove, doc, addDoc, setDoc, getDoc, arrayUnion, updateDoc, deleteDoc, collection, serverTimestamp, query, where, getDocs, onSnapshot, orderBy, writeBatch } from "firebase/firestore"; 
import { db } from '../firebase';

//=============================================================================================
//  CHAT
//=============================================================================================

// export const getUserConversations = async (userId) => {
//     try {
//         const userRef = doc(db, 'users', userId);
//         const userSnapshot = await getDoc(userRef);
//         if (userSnapshot.exists()) { return userSnapshot.data().conversations || []; } 
//         else { console.log('No such user!'); }
//     } catch (error) { console.error('Error fetching user:', error); return []; }  
// };

export const addConversation = async (conversationId, userId) => {
    const userRef = doc(db, 'users', userId);

    await setDoc(userRef, {
        conversations: arrayUnion(conversationId),
    }, { merge: true });
};

export const createChat = async (data) => {
  try {
    const ref = await addDoc(collection(db, 'conversations'), data);
    console.log(`New conversation created with id ${ref.id}`);
    return ref;
  } catch (e) {
    console.error("Error adding document: ", e);
  }
};

export const getChat = async (id) => {
  try {
    const ref = doc(db, 'conversations', id);
    const snap = await getDoc(ref);
    if (snap.exists()) { return snap.data() || []; } 
    else { console.log('No such chat!'); }
  } catch (e) {
    console.error("Error adding document: ", e);
  }
};

export const getChats = async (ownerId) => {
  try {
    const c = collection(db, 'conversations');
    const q = query(c, where('owner', '==', ownerId));
    const querySnapshot = await getDocs(q);

    const chats = querySnapshot.docs.map(doc => {
      return { id: doc.id, ...doc.data() };
    });

    return chats;

  } catch (e) {
    console.error("Error: ", e);
    return null;
  }
};

export const updateChat = async (id, data) => {
  try {
    const ref = doc(db, 'conversations', id);
    const snap = await getDoc(ref);

    if (snap.exists()) await setDoc(ref, data, { merge: true });
    else console.log(`No chat found with id ${id}`);

  } catch (e) {
    console.error("Error: ", e);
  }
};

export const subscribeMessages = (id, callback) => {
    const q = query(
      collection(db, "messages"), 
      where("conversation", "==", id),
      orderBy("timestamp", "asc")
    );
  
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      let messages = [];
      querySnapshot.forEach((doc) => {
        let data = doc.data();
        if (data.timestamp) { data.timestamp = data.timestamp.toDate(); }
        data.id = doc.id;
        messages.push(data);
      });
      callback(messages);
    });
  
    return unsubscribe;
  }

export const addMessage = async (id, message, userId, usage = {}, log = {}) => {
    const messagesCollectionRef = collection(db, 'messages');
    await addDoc(messagesCollectionRef, { message, owner: userId, conversation: id, timestamp: serverTimestamp(), usage, log });
}

export const deleteChat = async (id) => {
  await deleteMessages(id);
  await deleteConversation(id);
};

export const deleteMessages = async (id) => {
    const q = query(collection(db, "messages"), where("conversation", "==", id));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((documentSnapshot) => {
        deleteDoc(doc(db, "messages", documentSnapshot.id));
    });
};

export const deleteConversation = async (id) => {
  deleteDoc(doc(db, "conversations", id));
};

// export const getQueryVectorMessages = async (indexes) => {
//     try {
//       // Use Promise.all to fetch all documents at once
//       const promises = indexes.map(index => {
//         const docRef = doc(db, 'vectors', index); // Create a reference to the document
//         return getDoc(docRef); // Fetch the document
//       });
  
//       const snapshots = await Promise.all(promises);
//       const documents = snapshots.map(snapshot => {
//         if (snapshot.exists()) {
//           return snapshot.data(); // Return the document data
//         } else {
//           console.log('Document does not exist', snapshot.id);
//           return null;
//         }
//       }).filter(doc => doc !== null); // Filter out any null values
  
//       return documents; // Return the fetched documents
//     } catch (error) {
//       console.error('Error fetching vectors:', error);
//       return [];
//     }
//   };

//=============================================================================================
//  USER EDITING
//=============================================================================================

// export const addUser = async (db, user) => {
//   if (!user.email || !user.name || !user.organizations) { throw new Error('Invalid user data'); }
//   await setDoc(doc(db, 'users', user.id), user);
// };

// export const updateUser = async (db, userId, user) => {
//     console.log(db, userId, user);
//     // await updateDoc(doc(db, 'users', userId), user);
// };

// export const deleteUser = async (db, id) => {
//   await deleteDoc(doc(db, 'users', id));
// };

export const getUser = async (id) => {
    try {
        const userRef = doc(db, 'users', id);
        const userSnapshot = await getDoc(userRef);

        if (userSnapshot.exists()) { return userSnapshot.data(); } 
        else console.log('No such user!');
  
    } catch (error) {
        console.error('Error fetching user:', error);
    }  
};

//=============================================================================================
//  USER IN ORGANIZATION EDITING
//=============================================================================================

export const updateUserRole = async (id, role, orgId) => {
    const userRef = doc(db, 'users', id);
    const userSnap = await getDoc(userRef);

    if (userSnap.exists()) {
        const organizations = userSnap.data().organizations;
        const orgIndex = organizations.findIndex(org => org.id === orgId);
        if (orgIndex !== -1) {
            organizations[orgIndex].role = role;
        }
        await setDoc(userRef, { organizations }, { merge: true });
    } else {
        console.log(`No user found with id ${id}`);
    }
};

//=============================================================================================
//  FILE
//=============================================================================================

export const getFile = async (id) => {
  const ref = doc(db, 'files', id);
  const snap = await getDoc(ref);

  if (snap.exists()) { return snap.data(); } 
  else { console.log(`No file found with id ${id}`); }
};

export const updateFile = async (id, data) => {
  const d = doc(db, 'files', id);
  await updateDoc(d, data);
};

//=============================================================================================
//  OBJECT
//=============================================================================================

export const getObjects = async (owner) => {
  try {
    const c = collection(db, 'objects');
    const q = query(c, where('owner', '==', owner));
    const s = await getDocs(q);

    const docs = s.docs.map(doc => { return { id: doc.id, ...doc.data() }; });
    return docs;

  } catch (e) {
    return console.error("Error: ", e);
  }
};

//=============================================================================================
//  EXPERTS
//=============================================================================================

// export const getExpert = async (id) => {
//   const ref = doc(db, 'experts', id);
//   const snap = await getDoc(ref);

//   if (snap.exists()) { return snap.data(); } 
//   else { console.log(`No expert found with id ${id}`); }
// };

// export const getExperts = async (ownerId) => {
//   try {
//     const c = collection(db, 'experts');
//     const q = query(c, where('owner', '==', ownerId));
//     const querySnapshot = await getDocs(q);

//     const experts = querySnapshot.docs.map(doc => {
//       return { id: doc.id, ...doc.data() };
//     });

//     return experts;

//   } catch (e) {
//     console.error("Error: ", e);
//     return null;
//   }
// };

// export const updateExpert = async (id, data) => {
//   if (id) {
//     const ref = doc(db, 'experts', id);
//     const snap = await getDoc(ref);

//     if (snap.exists()) {
//       await setDoc(ref, data, { merge: true });
//     } else {
//       console.log(`No expert found with id ${id}`);
//     }
//   } else {
//     try {
//       const newDocRef = await addDoc(collection(db, 'experts'), data);
//       console.log(`New expert created with id ${newDocRef.id}`);
//     } catch (e) {
//       console.error("Error adding document: ", e);
//     }
//   }
// };

export const deleteExpert = async (id) => {
  await deleteDoc(doc(db, 'experts', id));
};

export const getExpertResources = async (id, type) => {
  const ref = doc(db, 'experts', id);
  const snap = await getDoc(ref);

  if (snap.exists()) {
    const resourceIds = snap.data().resources[type];
    if (resourceIds && resourceIds.length > 0) {
      const resourceDataPromises = resourceIds.map(async resourceId => {
        const resourceRef = doc(db, type, resourceId);  // Assuming 'type' is the collection name
        const resourceSnap = await getDoc(resourceRef);
        return resourceSnap.exists() ? resourceSnap.data() : null;
      });
      const resourcesData = await Promise.all(resourceDataPromises);
      return resourcesData.filter(data => data !== null);  // Filter out any null values from failed fetches
    } else {
      console.log(`No resources found for type ${type}`);
    }
  } else {
    console.log(`No expert found with id ${id}`);
  }
};

export const deleteExpertResource = async (id, type, resourceID) => {
  const expertRef = doc(db, 'experts', id);

  // Delete specific array element
  await updateDoc(expertRef, {
    [`resources.${type}`]: arrayRemove(resourceID)
  });
};

export const deleteExpertChats = async (id) => {
  try {
    const q = query(collection(db, "conversations"), where("expert", "==", id));
    const snap = await getDocs(q);

    const deletePromises = snap.docs.map(doc => deleteChat(doc.id));
    await Promise.all(deletePromises);

    return true;
  } catch (e) {
    console.error("Error: ", e);
    return false;
  }
};


//=============================================================================================
//  VECTORS
//=============================================================================================

export const getVectors = async (ownerid) => {
  try {
    const s = await getDocs(query(collection(db, "vectors"), where('owner', '==', ownerid)));
    const res = s.docs.map(doc => { return { id: doc.id, ...doc.data() }; });
    return res;
  } catch (e) {
    console.error("Error: ", e);
    return null;
  }
};

export const deleteVectors = async (ownerid) => {
  try {
    const q = query(collection(db, "vectors"), where("owner", "==", ownerid));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((documentSnapshot) => {
        deleteDoc(doc(db, "vectors", documentSnapshot.id));
    });
    return true;
  } catch (e) {
    console.error("Error: ", e);
    return false;
  }
};

//=============================================================================================
//  TAGS
//=============================================================================================

export const createTag = async (data) => {
  const c = collection(db, 'tags');
  const d = await addDoc(c, data);
  return d.id;
};

export const updateTag = async (id, data) => {
  const d = doc(db, 'tags', id);
  await updateDoc(d, data);
};

export const deleteTag = async (id) => {
  const d = doc(db, 'tags', id);
  await deleteDoc(d);
};

export const getTags = async (ownerId) => {
  try {
    const c = collection(db, 'tags');
    const q = query(c, where('owner', '==', ownerId));
    const s = await getDocs(q);

    const res = s.docs.map(doc => { return { id: doc.id, ...doc.data() }; });
    return res;

  } catch (e) {
    console.error("Error: ", e);
    return null;
  }
};

//=============================================================================================
//  Finetunes
//=============================================================================================

export const creatFinetune = async (data) => {
  const c = collection(db, 'finetunes');
  const d = await addDoc(c, data);
  return d.id;
};

export const getFinetune = async (id) => {
  const ref = doc(db, 'finetunes', id);
  const snap = await getDoc(ref);

  if (snap.exists()) { return snap.data(); } 
  else { console.log(`No finetune found with id ${id}`); }
};

export const updateFinetune = async (id, data) => {
  const d = doc(db, 'finetunes', id);
  await updateDoc(d, data);
};

export const deleteFinetune = async (id) => {
  const d = doc(db, 'finetunes', id);
  await deleteDoc(d);
};

export const getFinetunes = async (ownerId) => {
  try {
    const c = collection(db, 'finetunes');
    const q = query(c, where('owner', '==', ownerId));
    const s = await getDocs(q);

    const res = s.docs.map(doc => { return { id: doc.id, ...doc.data() }; });
    return res;

  } catch (e) {
    console.error("Error: ", e);
    return null;
  }
};