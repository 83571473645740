import React, { useState, useEffect, useContext } from 'react';
import { UserContext } from '../UserProvider';
import { TextField, Container, Toolbar, Typography, Button, Box, Drawer, List, ListItem, ListItemIcon, ListItemText, Divider } from '@mui/material';
import { deleteNamespace, deleteVectors } from '../Utilities/apiConnector';
import { deleteVectors as deleteFirestoreVectors } from '../Utilities/firestoreConnector'

function Settings() {
  const [ namespace, setNamespace ] = useState('');
  const [ ownerid, setOwnerid ] = useState('');

  const handleDeleteNamespace = async (e) => {
    e.preventDefault();
    const resp = await deleteNamespace("pinecone", namespace);
    console.log(resp);
  };

  const handleDeleteVectors = async (e) => {
    e.preventDefault();
    const resp = await deleteVectors(ownerid, 'pinecone', 'dev');
    console.log(resp);
  };

  const handleDeleteFirestoreVectors = async (e) => {
    e.preventDefault();
    const resp = await deleteFirestoreVectors(ownerid);
    console.log(resp);
  };

  return (
    <>
      Settings Page
      <Container>
      <form onSubmit={handleDeleteNamespace}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="namespace"
            label="Namespace Name"
            name="namespace"
            autoFocus
            value={ namespace }
            onChange={(e) => setNamespace(e.target.value)}
          />
          
          <Button type="submit" fullWidth variant="contained" color="primary" style={{ marginTop: '10px' }}>Nuke Namespace</Button>
        </form>

        <form onSubmit={handleDeleteVectors}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="ownerid"
            label="Owner ID"
            name="ownerid"
            autoFocus
            value={ ownerid }
            onChange={(e) => setOwnerid(e.target.value)}
          />
          
          <Button type="submit" fullWidth variant="contained" color="primary" style={{ marginTop: '10px' }}>Nuke Vectors</Button>
        </form>

        <form onSubmit={handleDeleteFirestoreVectors}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="ownerid"
            label="Owner ID"
            name="ownerid"
            autoFocus
            value={ ownerid }
            onChange={(e) => setOwnerid(e.target.value)}
          />
          
          <Button type="submit" fullWidth variant="contained" color="primary" style={{ marginTop: '10px' }}>Nuke Firestore Vectors</Button>
        </form>
      </Container>
    </>
    );
}

export default Settings;