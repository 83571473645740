import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Select, MenuItem, Button, TextareaAutosize, FormControl, Typography, Container, TextField, Grid, Paper } from '@mui/material';
import { getFinetune, updateFinetune, deleteFinetune } from '../../Utilities/firestoreConnector';
import { removeUserFromOrganization } from '../../Utilities/apiConnector';

const Type = {
  Finetune : 'finetune',
  Instructions : 'instructions'
};

const Roles = {
  System : 'system',
  Assistant : 'assistant',
  User : 'user'
};

function Finetune() {
  const { userId, orgId, finetuneId } = useParams();
  const [data, setData] = useState(null);
  const [update, setUpdate] = useState(null);
  const [inSync, setInSync] = useState(true);
  const [type, setType] = useState(Type.Finetune);
  const navigate = useNavigate();

  useEffect(() => {
    const loadData = async () => { return await getFinetune(finetuneId); };
    
    const fetchData = async () => {
      const data = await loadData();
      setData(data);
      setUpdate(data);
    };
    
    fetchData();
  }, []);

  const handleSave = async () => { await updateFinetune(finetuneId, {...data, ...update}); setInSync(true); };
  const handleNameChange = (name) => { setUpdate({ ...update, name }); setInSync(data.name === name); };
  const handleInstructionChange = (instructions) => { setUpdate({ ...update, instructions }); setInSync(data.instructions === instructions); };
  const handleQueryChange = (query) => { setUpdate({ ...update, query }); setInSync(data.query === query); };
  const handleResponseChange = (response) => { setUpdate({ ...update, response }); setInSync(data.response === response); };
  const handleRoleChange = (role) => { setUpdate({ ...update, role }); setInSync(data.role === role); };

  // const handleTypeChange = (type) => { setType(type); setUpdate({ ...update, type }); setInSync(data.type === type); };
  // const handleDelete = async () => {
  //   await deleteFinetune(finetuneId);
  //   navigate(`/organizations/${orgId}/finetuning`);
  // };

  return (
    <Container>
      {data &&
        <Paper elevation={3} sx={{ p: 2, m: 2 }}>
          <Typography variant="h5" component="h1" sx={{ mb: 2 }}>{update.type}</Typography>
          <TextField value={update.name} onChange={(e) => handleNameChange(e.target.value)} label="Name" fullWidth sx={{ mb: 2 }}/>
          <Grid container spacing={2}>
            {update.type === Type.Finetune ?
            <>
              <Grid item xs={6}>
                <TextField
                  value={update.query}
                  onChange={(e) => handleQueryChange(e.target.value)}
                  label="Query"
                  fullWidth
                  multiline
                  variant="outlined"
                  InputProps={{
                    style: { height: 'auto' },
                  }}
                  sx={{ marginBottom: 2 }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  value={update.response}
                  onChange={(e) => handleResponseChange(e.target.value)}
                  label="Response"
                  fullWidth
                  multiline
                  variant="outlined"
                  InputProps={{
                    style: { height: 'auto' },
                  }}
                  sx={{ marginBottom: 2 }}
                />
              </Grid>
            </>
            :
            <Grid item xs={12}>
              <TextField
                value={update.instructions}
                onChange={(e) => handleInstructionChange(e.target.value)}
                label="Instructions"
                fullWidth
                multiline
                variant="outlined"
                InputProps={{ style: { height: 'auto' }, }}
                sx={{ marginBottom: 2 }}
              />
            </Grid>
            }
          </Grid>
          <Grid>
            <Grid item>
              <Select value={update.role ?? Roles.User} onChange={(e) => handleRoleChange(e.target.value)}>
                <MenuItem value={Roles.System}>System</MenuItem>
                <MenuItem value={Roles.Assistant}>Assistant</MenuItem>
                <MenuItem value={Roles.User}>User</MenuItem>
              </Select>
            </Grid>
            <Grid item>
              <Button variant="contained" color="primary" onClick={handleSave} disabled={inSync} sx={{ marginTop: 2, marginRight: 2 }}>Save</Button>
              {/* <Button variant="contained" color="secondary" onClick={handleDelete} sx={{ marginTop: 2 }}>Delete</Button> */}
            </Grid>
          </Grid>

        </Paper>
      }
    </Container>
  );
}

export default Finetune;