import React, { useState, useEffect, useRef } from 'react';
import { Canvas, useThree } from '@react-three/fiber';
import { Box3, Vector3, Raycaster, Vector2, MOUSE } from 'three';
import { RGBELoader } from 'three/addons/loaders/RGBELoader.js';
import * as THREE from 'three';
import { EffectComposer, SSAO } from "@react-three/postprocessing";
import { BlendFunction } from "postprocessing";
import { OrbitControls } from 'three/addons/controls/OrbitControls.js';
import CircularProgress from '@mui/material/CircularProgress';
import { Container, Slider } from '@mui/material';
import { load3DModel, updateObject3DMaterial, loadEnvironmentMap, adjustCameraToViewAllPoints, extractPointsFromBoundingBox } from '../Utilities/scene3D';

function Model({ name, url, setLoading, onMesh, currentSubmesh }) {
  const { scene, camera, gl } = useThree();
  const [mesh, setMesh] = useState(null);
  const controlsRef = useRef({});
  const defaultMaterials = useRef({});
  const objScale = 0.01;

  const bodyMaterial = new THREE.MeshPhysicalMaterial({
    color: 0xaaaaaa, metalness: 1, roughness: 0.5, clearcoat: 0.0, clearcoatRoughness: 0.03
  });
  const highlightMaterial = new THREE.MeshStandardMaterial({ color: 0xff2222, metalness: 1, roughness: 0.1 });

  useEffect(() => {
    let isMounted = true;
    const EnvMap = loadEnvironmentMap('/hdri/');

    const loadModel = async () => {
      try {
        const object3D = await load3DModel({ name, url });
        if (isMounted) {
          object3D.scale.set(objScale, objScale, objScale);
          onMesh(object3D);
          updateObject3DMaterial(object3D, EnvMap, 0.5, 0.5, bodyMaterial);

          scene.background = new THREE.Color(0xaaaaaa);

          const pmremGenerator = new THREE.PMREMGenerator(gl);
          const hdriLoader = new RGBELoader()
          hdriLoader.load('/hdri/cube.hdr', function (texture) {
            const envMap = pmremGenerator.fromEquirectangular(texture).texture;
            texture.dispose();
            scene.environment = envMap
          });

          scene.fog = new THREE.Fog(0xaaaaaa, 10, 15);
          scene.add(object3D);

          let grid = new THREE.GridHelper(100, 200, 0xffffff, 0xffffff);
          grid.material.opacity = 0.2;
          grid.material.depthWrite = false;
          grid.material.transparent = true;
          scene.add(grid);

          object3D.traverse((child) => { if (child.isMesh) defaultMaterials.current[child.name] = child.material; });

          camera.fov = 45;
          camera.near = 0.01;

          setMesh(object3D);
          setLoading(false);
        }
      } catch (error) {
        console.error(error);
      }
    };

    loadModel();

    return () => {
      isMounted = false;
    };
  }, [url, name]);

  useEffect(() => {
    if (!mesh) return;
    if (currentSubmesh) {
      mesh.traverse((child) => {
        if (child.isMesh) {
          if (child.name == currentSubmesh.name) child.material = highlightMaterial;
          else if (defaultMaterials.current[child.name]) child.material = defaultMaterials.current[child.name];
        }
      });
    } else {
      mesh.traverse((child) => { if (child.isMesh) { child.material = defaultMaterials.current[child.name]; } });
    }
  }, [currentSubmesh]);

  useEffect(() => {
    if (!mesh) return;
    const box = new Box3().setFromObject(mesh);

    const controls = new OrbitControls(camera, gl.domElement);
    controlsRef.current = controls;

    controls.mouseButtons = { LEFT: MOUSE.ROTATE, MIDDLE: MOUSE.PAN, RIGHT: MOUSE.DOLLY };
    controls.maxDistance = 10;
    controls.maxPolarAngle = THREE.MathUtils.degToRad(120);
    controls.target.set(0, (box.max.y - box.min.y) * 0.5, 0);

    controls.update();

    // controlsRef.current.dispose();
  }, [mesh]);

  return null;
}

function Effects() {
  return (
    <EffectComposer>
      <SSAO
        blendFunction={BlendFunction.MULTIPLY} // Use NORMAL to see the effect
        samples={31}
        radius={5}
        intensity={30}
      />
    </EffectComposer>
  );
}

function Display({ objectData, meshData, currentSubmesh }) {
  const [loading, setLoading] = useState(true);

  const handleMesh = (mesh) => {
    meshData(mesh);
  }

  return (
    <Container sx={{ height: 400 }}>
      {loading && <CircularProgress />}
      <Canvas>
        <ambientLight />
        <Model
          name={objectData.fileName}
          url={objectData.fileURL}
          setLoading={setLoading}
          onMesh={handleMesh}
          currentSubmesh={currentSubmesh}
        // onMeshScale={handleMeshScale}
        // onHideToggle={handleHideToggle}
        />
      </Canvas>
    </Container>
  );
}

export default Display;


