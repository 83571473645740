import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, TextField, Container, Box, Typography, Checkbox, FormControlLabel, Paper, Grid, Divider } from '@mui/material';
import { getAuth, confirmPasswordReset, verifyPasswordResetCode } from "firebase/auth";
import axios from 'axios';

const auth = getAuth();

const DEV = false;

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function Activation() {
  const landingPageUrl = '/';

  const query = useQuery();
  const navigate = useNavigate();

  const [organization, setOrganization] = useState('');
  const [email, setEmail] = useState('');
  const [token, setToken] = useState('');
  const [resetLink, setResetLink] = useState('');
  const [displayName, setDisplayName] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [termsAccepted, settermsAccepted] = useState(false);
  const [activationStatus, setActivationStatus] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    setOrganization(query.get('organization'));
    setEmail(query.get('email'));
    setToken(query.get('token'));
    setResetLink(query.get('resetLink'));
  }, [query]);

  const isValidName = (name) => /^[a-zA-Z0-9\s]+$/ .test(name) && name !== '';
  const isValidPassword = (pass) => /^[a-zA-Z0-9 ._\-]+$/ .test(pass) && pass !== '';

  const arePasswordsMatching = password === confirmPassword;
  const isJoinButtonDisabled = !termsAccepted;
  const isCreateAccountButtonDisabled = !termsAccepted || !isValidName(displayName) || (!arePasswordsMatching || !isValidPassword(password) || !isValidPassword(confirmPassword));

  const handleActivate = async () => {
    try {
      const url = DEV ? 'http://localhost:5001/dev-immersion-ai-assistant/europe-west1/v2/auth/user/acceptinvitation' : 'https://dev-europe-west1-immersion-ai-assistant.cloudfunctions.net/v2/auth/user/acceptinvitation';
      const resp = await axios.post(url, { email, token, termsAccepted, active: true, displayName }, { headers: { 'Content-Type': 'application/json' }});
      if (resp.status === 200) { setActivationStatus('User successfully joined'); navigate(landingPageUrl); }
      else throw new Error('User join failed');
    } catch (error) {
      setActivationStatus(`Failed: ${error.message}`);
      setError(error.message);
    }
  };

  const handleCreateAccountAndAccept = async (event) => {
    event.preventDefault();
    try {
      const resetUrl = new URL(resetLink);
      const oobCode = resetUrl.searchParams.get('oobCode');
      const emailFromCode = await verifyPasswordResetCode(auth, oobCode);
      if (emailFromCode !== email) throw new Error("Email mismatch: the code belongs to a different email");
      await confirmPasswordReset(auth, oobCode, password);
      const url = DEV ? 'http://localhost:5001/dev-immersion-ai-assistant/europe-west1/v2/auth/user/acceptinvitation' : 'https://dev-europe-west1-immersion-ai-assistant.cloudfunctions.net/v2/auth/user/acceptinvitation';
      const resp = await axios.post(url, { email, token, termsAccepted, active: true, displayName }, { headers: { 'Content-Type': 'application/json' }});
      if (resp.status === 200) { setActivationStatus('User successfully activated and password reset'); navigate(landingPageUrl); }
      else throw new Error('User activation failed');
    } catch (error) {
      setActivationStatus(`Failed: ${error.message}`);
      setError(error.message);
    }
  };

  return (
    <Container maxWidth="sm">
      <Box sx={{ mt: 7, mb: 3 }}>
        <Typography variant="h5" gutterBottom>{organization} invites you to join AI Assistant App!</Typography>
        <FormControlLabel control={<Checkbox checked={termsAccepted} onChange={(e) => settermsAccepted(e.target.checked)} />} label="Accept Terms and Conditions" />
      </Box>
      <Paper elevation={3} sx={{ padding: 2 }}>
        {resetLink && (
          <>
            <Typography variant="h6" gutterBottom>Account creation</Typography>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField fullWidth label="Display Name" type="name" value={displayName} onChange={(e) => setDisplayName(e.target.value)} required />
              </Grid>
              <Grid item xs={12}>
                <TextField fullWidth label="New Password" type="password" value={password} onChange={(e) => setPassword(e.target.value)} error={!isValidPassword(password)} helperText={(!isValidPassword(password) && password !== '') && "Invalid password. Use alphanumeric characters only."} />
              </Grid>
              <Grid item xs={12}>
                <TextField fullWidth label="Confirm Password" type="password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} error={!arePasswordsMatching && confirmPassword !== ''} helperText={(!arePasswordsMatching && confirmPassword !== '') && "Passwords do not match."} />
              </Grid>
            </Grid>
            <Divider sx={{ margin: '20px 0' }} />
            <Button variant="contained" color="primary" onClick={handleCreateAccountAndAccept} disabled={isCreateAccountButtonDisabled} fullWidth>Create Account and Accept Invitation</Button>
          </>
        )}
        {!resetLink && (
          <>
            <Typography variant="h6" gutterBottom>Account Activation</Typography>
            <Divider sx={{ margin: '20px 0' }} />
            <Button variant="contained" color="primary" onClick={handleActivate} disabled={isJoinButtonDisabled} fullWidth>Join</Button>
          </>
        )}
        <Typography variant="h7" sx={{ color: 'red', marginTop: 2 }}>{error}</Typography>
      </Paper>
    </Container>
  );
}

export default Activation;