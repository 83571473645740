import React, { useContext } from 'react';
import { UserContext } from './UserProvider';
import { Navigate } from 'react-router-dom';

const ProtectedComponent = ({children, roles}) => {
  const { userData } = useContext(UserContext);
  
  if (userData &&
      userData.organizations &&
      userData.currentOrganization &&
      roles.includes(userData.organizations.find(org => org.id === userData.currentOrganization).role)
     ) {
    return children;
  } else {
    return <Navigate to="/dashboard" />;
  }
};

export default ProtectedComponent;