import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { Typography, TextField, Button, Box, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import { addUserToOrganization, getCoreResource } from '../Utilities/apiConnector';
import { ConfigContext } from '../ConfigProvider';

function AddUser() {
  const { orgId } = useParams();
  const [email, setEmail] = useState('');
  const [role, setRole] = useState('');
  const [org, setOrg] = useState();

  useEffect(() => {
    const getOrganization = async () => {
      const organization = (await getCoreResource('organization', orgId)).data;
      setOrg(organization);
    }

    getOrganization();
  }, [orgId]);

  const { Roles } = useContext(ConfigContext);
  useEffect(() => { if (Roles) { setRole(Roles.Reader); } }, [Roles]);

  const handleEmailChange = (event) => { setEmail(event.target.value); };

  const handleRoleChange = (event) => { setRole(event.target.value); };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try{
      await addUserToOrganization(orgId, email, role);
      setEmail('');
    }catch (error){
      console.error(`Error: ${error}`);
    }
  };

  return (
    <Box sx={{ maxWidth: 400, margin: 'auto' }}>
      <Typography variant="subtitle1" gutterBottom>
        Add User to {org?.name}
      </Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          label="Email"
          value={email}
          onChange={handleEmailChange}
          type="email"
          fullWidth
          required
          margin="normal"
        />
        <FormControl fullWidth margin="normal">
          <InputLabel>Role</InputLabel>
          <Select value={role} onChange={handleRoleChange}>
            {Roles && Object.keys(Roles).map((roleName) => ( <MenuItem key={roleName} value={Roles[roleName]}> {roleName} </MenuItem> ))}
          </Select>
        </FormControl>
        <Button variant="contained" type="submit" color="primary">
          Invite
        </Button>
      </form>
    </Box>
  );
}

export default AddUser;
