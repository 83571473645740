import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { doc, onSnapshot } from 'firebase/firestore';
import { getDownloadURL } from 'firebase/storage';
import { db } from '../firebase';
import { Box, Container, Card, CardContent, CardHeader, Grid, Paper, Chip, Stack, Typography, Divider, TextField, Slider, Button } from '@mui/material';
import ObjectDisplay from '../Components/ObjectDisplay';
import { updateResource, getResources, setResource, deleteResource, getObject, setTag, updateTag } from '../Utilities/apiConnector';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

function Object() {
  const { objectId } = useParams();
  const [object, setObject] = useState({});
  const [tagList, setTagList] = useState([]);
  const [meshData, setMeshData] = useState(null);
  const [currentSubmesh, setCurrentSubmesh] = useState();
  const [currentTag, setCurrentTag] = useState();
  const [currentTagUpdate, setCurrentTagUpdate] = useState();
  const [meshScale, setMeshScale] = useState();
  const [submeshes, setSubmeshes] = useState([]);
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    fetchData();
  }, [objectId]);

  const fetchData = async () => {
    const data = (await getObject(objectId)).data;
    console.log(data);
    // let url = null;
    // try { url = await getDownloadURL(data.fileRef); } catch (e) { console.log(e); }
    // console.log(url);
    setObject(data);
  };

  useEffect(() => {
    fetchTags();
  }, [refresh]);

  const fetchTags = async () => {
    const tags = (await getResources('tag', objectId)).data;
    setTagList(tags);

    if (currentSubmesh) {
      const tag = tags.find(tag => tag.submesh?.name === currentSubmesh.name);
      setCurrentTag(tag);
      setCurrentTagUpdate(tag);
    }
  };

  const handleSelectSubmesh = (submesh) => {
    setCurrentSubmesh(submesh);
    const tag = tagList.find(tag => tag.submesh?.name === submesh.name);
    setCurrentTag(tag);
    setCurrentTagUpdate(tag);
  };


  const handleCreateTag = async () => {
    const newTag = {
      name: 'New Tag',
      owner: objectId,
      referers: 'New Referers',
      submesh: { name: currentSubmesh.name },
    };
    await setTag(newTag);
    setRefresh(!refresh);
  };

  const handleUpdateTag = (name, value) => {
    const update = { ...currentTagUpdate, [name]: value };
    setCurrentTagUpdate(update);
  };

  const handleDeleteTag = async (e) => {
    const resp = await deleteResource('tag', currentTag.id);
    console.log(resp);
    setRefresh(!refresh);
  };

  const handleMeshScale = (e, v) => { if (meshData) { meshData.scale.set(v * 0.01, v * 0.01, v * 0.01); setMeshScale(v); } };

  const handleHideSubmesh = (e, v) => {
    if (!meshData) return;
    meshData.traverse((s) => { if (s.isMesh) { if (s.uuid === currentSubmesh.uuid) s.visible = !s.visible; } });
  };

  const handleUnhideAll = (e, v) => {
    if (!meshData) return;
    meshData.traverse((s) => { if (s.isMesh) s.visible = true; });
  };

  const handleSaveData = async () => {
    const { id, ...update } = currentTagUpdate;
    await updateTag(id, update);
    setRefresh(!refresh);
  };

  const processMeshData = (m) => {
    setMeshData(m);
    // m.traverse((s) => { if (s.isMesh) console.log(s) });
  };

  useEffect(() => {
    if (!meshData) return;
    let submeshes = [];
    meshData.traverse((s) => { if (s.isMesh) submeshes.push(s); });
    setSubmeshes(submeshes);
  }, [meshData]);

  return (
    <Container>
      <Grid container spacing={1}>
        {object?.fileURL &&
          <>
            <Grid item xs={5}>
              <Paper sx={{ p: 1, mb: 1 }}>
                <ObjectDisplay
                  objectData={object}
                  meshData={processMeshData}
                  currentSubmesh={currentSubmesh}
                />
              </Paper>
              <Paper sx={{ p: 1, mb: 1 }}>
                Scale: {meshScale}
                <Slider defaultValue={1} max={10} min={0.01} step={0.01} onChange={handleMeshScale} />
              </Paper>
            </Grid>

          </>
        }
        <Grid item xs={7}>
          <Paper sx={{ p: 1 }}>
            <Typography variant="h6">Select Part:</Typography>
            <Divider sx={{ mb: 1 }} />
            <Stack spacing={1} direction="row" useFlexGap flexWrap="wrap">
              {submeshes.map((submesh, index) => (
                <Chip
                  size="small"
                  icon={tagList.find(t => t?.submesh?.name === submesh?.name) ? <CheckCircleIcon color="success" /> : null}
                  color={submesh?.name == currentSubmesh?.name ? "primary" : "default"}
                  key={index}
                  label={submesh?.name}
                  variant={submesh?.name == currentSubmesh?.name ? "contained" : "outlined"}
                  onClick={() => handleSelectSubmesh(submesh)} />
              ))}
            </Stack>
          </Paper>
        </Grid>
      </Grid>
      {currentSubmesh && (
        tagList.find(t => t?.submesh?.name === currentSubmesh?.name) ?
          <Paper sx={{ p: 1, mb: 1 }}>
            <Stack spacing={1}>
              <Typography variant="h6">Part: {currentSubmesh?.name}, {currentTag?.id}</Typography>
              <Divider sx={{ mb: 1 }} />
              <Stack spacing={1} direction="row" useFlexGap flexWrap="wrap">
                <Button variant='contained' onClick={handleSaveData}>Save</Button>
                <Button variant='outlined' onClick={handleHideSubmesh}>Hide Toggle</Button>
                <Button variant='outlined' onClick={handleUnhideAll}>Unhide All</Button>
                <Button variant='contained' color='secondary' onClick={handleDeleteTag}>Delete</Button>
              </Stack>
              <Divider sx={{ mb: 1 }} />
              <TextField value={currentTagUpdate?.name} onChange={(e) => handleUpdateTag('name', e.target.value)} label="Tag Name" size="small" />
              <TextField value={currentTagUpdate?.referers} onChange={(e) => handleUpdateTag('referers', e.target.value)} label="Referers" size="small" />
            </Stack>
          </Paper>
          :
          <Paper sx={{ p: 1, mb: 1 }}>
            <Button variant='contained' onClick={handleCreateTag}>Create Tag</Button>
          </Paper>)}

    </Container>
  );
}

export default Object;
