import React, { useState, useEffect, useContext } from 'react';
import ObjectUploader from '../Components/ObjectUploader';
import ObjectList from '../Components/ObjectList';
import { UserContext } from '../UserProvider';
import { Stack, Paper, TableContainer } from '@mui/material';

function Objects() {
  const { user, userData, updateUser } = useContext(UserContext);
  const [fileURL, setFileURL] = useState(null);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [fileUploadTrigger, setFileUploadTrigger] = useState(0);

  const handleFileSelection = (files) => {
    setFileURL(files[0]?.url);
    setSelectedFiles(files);
  };

  return (
    <TableContainer>
      {userData && userData.currentOrganization && (
        <Stack>
          <ObjectList onSelectFiles={handleFileSelection} category={'organizations'} type={"object"} collectionName={"objects"} id={userData?.currentOrganization} refreshKey={fileUploadTrigger} />
          <ObjectUploader owner={userData?.currentOrganization} type={"object"} />
        </Stack>
      )}
    </TableContainer>
  );
}

export default Objects;
