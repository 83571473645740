import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Typography, Container, Paper, Button } from '@mui/material';
import { getResource, saveToOpenAI, deleteFromOpenAI } from '../../Utilities/apiConnector';

function File() {
  const { userId, orgId, fileId } = useParams();
  const [data, setData] = useState(null);
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    const loadData = async () => { return await getResource('file', fileId); };
    
    const fetchData = async () => {
      const data = await loadData();
      console.log(data);
      setData(data);
    };
    
    fetchData();
  }, [refresh]);

  const handleUploadToOpenAI = async () => { await saveToOpenAI('file', fileId, { purpose: 'assistants' }); setRefresh(!refresh); };
  const handleDeleteFromOpenAI = async () => { await deleteFromOpenAI('file', fileId); setRefresh(!refresh); };

  return (
    <Container>
      <Paper sx={{ p: 2, mb: 2 }}><Typography variant="h5" component="h2">{data?.data?.fileName}</Typography></Paper>
      <Paper sx={{ p: 2, mb: 2, wordWrap: 'break-word' }}>
        {/* <Typography variant="body2">{JSON.stringify(data?.data)}</Typography> */}
        <Typography variant="body2">ID: {fileId}</Typography>
        <Typography variant="body2">Owner: {data?.data?.owner}</Typography>
        <Typography variant="body2">Type: {data?.data?.type}</Typography>
        <Typography variant="body2">Status: {data?.data?.status}</Typography>
        <Typography variant="body2">File Size: {data?.data?.metadata?.size ? (data?.data?.metadata?.size / (1024 * 1024)).toFixed(2) + ' MB' : ""}</Typography>
        <Typography variant="body2">File Type: {data?.data?.metadata?.contentType}</Typography>
        <Typography variant="body2">Ref: {data?.data?.fileRef}</Typography>
        <Typography variant="body2">External Ref: {JSON.stringify(data?.data?.externalRef)}</Typography>
      </Paper>
      <Button sx={{ mr: 2 }} variant="contained" color="primary" size="small" onClick={(e) => { handleUploadToOpenAI(); }}>Upload to OpenAI</Button>
      <Button sx={{ mr: 2 }} variant="contained" color="secondary" size="small" onClick={(e) => { handleDeleteFromOpenAI(); }}>Delete from OpenAI</Button>
    </Container>
  );
}

export default File;
