import React, { useState, useEffect } from 'react';
import { collection, onSnapshot, doc, deleteDoc, query, where, getDocs } from '@firebase/firestore';
import { db } from '../firebase';
import { useNavigate } from 'react-router-dom';
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { styled } from '@mui/system';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: theme.spacing(1, 2),
}));

const StripedTableRow = styled(TableRow)(({ index }) => index % 2 ? 
  { background: '#f7f7f7' } : 
  {});

function FirestoreCollectionTable({ collectionName, filterField, filterValue }) {

  const [entries, setEntries] = useState([]);
  const [selectedEntryId, setSelectedEntryId] = useState(null);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      const col = collection(db, collectionName);
      const q = filterField && filterValue ? query(col, where(filterField, "array-contains", filterValue)) : col;
      const querySnapshot = await getDocs(q);
      const newEntries = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setEntries(newEntries);
    };

    fetchData();
  }, [collectionName, filterField, filterValue]);

  const handleRowClick = (entryId) => {
    navigate(`${entryId}`);
  };

  const handleDeleteClick = (entryId) => {
    setSelectedEntryId(entryId);
    setConfirmDeleteOpen(true);
  };

  const handleDeleteConfirm = async () => {
    try {
      await deleteDoc(doc(db, collectionName, selectedEntryId));
      setConfirmDeleteOpen(false);
      setSelectedEntryId(null);
    } catch (error) {
      console.error(`Error deleting ${collectionName} entry:`, error);
    }
  };

  const handleDeleteCancel = () => {
    setSelectedEntryId(null);
    setConfirmDeleteOpen(false);
  };

  return (
    <Box mt={4} p={2} component={Paper}>
      <Typography variant="h6" component="h1" gutterBottom>
        {collectionName}
      </Typography>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell>Entry</StyledTableCell>
              <StyledTableCell>Action</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {entries.length > 0 ? (
              entries.map((entry, index) => (
                <StripedTableRow 
                  key={entry.id} 
                  hover
                  onClick={() => handleRowClick(entry.id)}
                  index={index}
                >
                  <StyledTableCell>
                    <pre>{JSON.stringify(entry, null, 2)}</pre>
                  </StyledTableCell>
                  <StyledTableCell>
                    <IconButton 
                      style={{ color: '#f50057', border: '1px solid #f50057' }}
                      variant="outlined" 
                      onMouseOver={(e) => e.currentTarget.style.backgroundColor = '#f50057'}
                      onMouseOut={(e) => e.currentTarget.style.backgroundColor = 'transparent'}
                      onClick={(event) => {
                        event.stopPropagation();
                        handleDeleteClick(entry.id);
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </StyledTableCell>
                </StripedTableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={2} style={{ textAlign: "center" }}>
                  No entries found
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog open={confirmDeleteOpen} onClose={handleDeleteCancel}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          Are you sure you want to delete this entry?
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteCancel} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteConfirm} color="secondary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default FirestoreCollectionTable;
