import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { collection, addDoc, getDocs } from 'firebase/firestore';
import { db } from '../firebase';
import { Button, TextField, Container, Typography, List, ListItem, ListItemText } from '@mui/material';

function AddOrganization() {
    const { register, handleSubmit, errors } = useForm();
    const [organizations, setOrganizations] = useState([]);
    
    useEffect(() => {
      const fetchOrganizations = async () => {
        const querySnapshot = await getDocs(collection(db, 'organizations'));
        setOrganizations(querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
      };

      fetchOrganizations();
    }, []);;
    
    const onSubmit = async data => {
      try {
        const usersArray = typeof data.users === 'string' ? data.users.split(',').map(user => user.trim()) : [];
        const groupsArray = typeof data.groups === 'string' ? data.groups.split(',').map(group => group.trim()) : [];
        const rolesArray = typeof data.roles === 'string' ? data.roles.split(',').map(role => role.trim()) : [];
  
        await addDoc(collection(db, 'organizations'), {
          name: data.name,
          users: usersArray,
          groups: groupsArray,
          roles: rolesArray,
        });
  
        console.log('Organization added successfully');
      } catch (error) {
        console.error('Error adding organization: ', error);
      }
    };
  
    return (
      <Container component="main" maxWidth="xs">
        <Typography component="h1" variant="h5">
          Add Organization
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="name"
            label="Organization Name"
            name="name"
            autoComplete="name"
            autoFocus
            {...register('name')}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
          >
            Add Organization
          </Button>
        </form>
      </Container>
    );
  }
  
  export default AddOrganization;