import * as React from 'react';
import { Card, Typography, Button } from '@mui/material';
import { styled } from '@mui/system';
import { grey, blue } from '@mui/material/colors';
import { IconButton } from '@mui/material';


import InfoIcon from '@mui/icons-material/Info';

const UserMessage = styled(Card)(({ theme }) => {
    return {
      margin: '10px',
      padding: '10px',
      backgroundColor: blue[900],
      color: theme.palette.primary.contrastText,
      alignSelf: 'flex-end',
    };
  });

const OtherMessage = styled(Card)(({ theme }) => ({
    margin: '10px',
    padding: '10px',
    backgroundColor: grey[300],
    alignSelf: 'flex-start',
  }));

function ChatBubble({ message, isUser, infoHandle }) {
  const MessageCard = isUser ? UserMessage : OtherMessage;
  return (
    <MessageCard>
      <Typography variant="body1" style={{ whiteSpace: 'pre-line' }}>{message}</Typography>
      {infoHandle && <IconButton color="info" onClick={infoHandle}><InfoIcon /></IconButton>}
    </MessageCard>
  );
}

export default ChatBubble;
