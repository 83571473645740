import React, { useState, useEffect, useContext, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Button, Grid, Container, Paper, TableContainer } from '@mui/material';
import { createChat } from '../Utilities/firestoreConnector';
import { UserContext } from '../UserProvider';
import ExpertSelector from '../Components/ExpertSelector';
import ChatTable from '../Components/ChatTable';
import { getResource, createThread } from '../Utilities/apiConnector';

import { eventManager } from '../Utilities/eventManager';
import { useError } from '../Components/contexts/ErrorContext';
import { useNotification } from '../Components/contexts/NotificationContext';

export default function Chats() {
  const { orgId } = useParams();
  const { user, userData, updateUser } = useContext(UserContext);
  const [expert, setExpert] = useState();
  // const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  // const { showError, hasError } = useError();
  // const { addNotification } = useNotification();

  // useEffect(() => {
  //   const handleError = (error) => { showError(error); };
  //   eventManager.subscribe('api-error', handleError);
  //   return () => { eventManager.unsubscribe('api-error', handleError); };
  // }, []);

  const handleCreate = async () => {

    try {
      const newChat = {
        owner: user.uid,
        expert: expert
      };

      const expertData = (await getResource('expert', expert)).data;

      let resp;
      if (expertData?.externalRef?.openai) {
        console.log("--- onepai thread ---");
        resp = (await createThread(newChat)).data;
      } else {
        resp = await createChat(newChat);
      }

      navigate(`/chats/${resp.id}`);
    } catch (error) {
    }
  };

  const handleSelectExpert = (selectedExpertId) => {
    setExpert(selectedExpertId);
  };

  // if (loading) { return <>Loading...</>; }

  return (
    <Container component={Paper} style={{ padding: '20px' }}>

      <Grid sx={{ mb: 2 }} container spacing={3}>
        <Grid item xs={10}>
          <Box>
            {userData?.currentOrganization && <ExpertSelector orgId={userData.currentOrganization} onSelect={handleSelectExpert} />}
          </Box>
        </Grid>
        <Grid item xs={2}>
          <Box>
            <Button variant="contained" color="primary" onClick={handleCreate} >Create Chat</Button>
          </Box>
        </Grid>
      </Grid>
      <ChatTable />
    </Container>
  );
}