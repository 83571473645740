import React, { useState, useEffect } from 'react';
import { collection, query, where, getDocs } from '@firebase/firestore';
import { db } from '../firebase';
import { useNavigate, useParams } from 'react-router-dom';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PendingIcon from '@mui/icons-material/Pending';
import { Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Button, Chip, Stack } from '@mui/material';
import { styled } from '@mui/system';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: theme.spacing(1, 2),
}));

const StripedTableRow = styled(TableRow)(({ index }) => index % 2 ? 
  { background: '#f7f7f7' } : 
  {});

function ListOrganizationUsers({ collectionName, filterField, filterValue }) {
  const { orgId } = useParams();
  const [entries, setEntries] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      const col = collection(db, 'users');
      const q = filterField && filterValue ? query(col, where("orgIDs", "array-contains", filterValue)) : col;
      const querySnapshot = await getDocs(q);
      const newEntries = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setEntries(newEntries);
    };
  
    fetchData();
  }, [collectionName, filterField, filterValue]);

  const handleEditUser = (entryId) => {
    navigate(`/organizations/${filterValue}/${collectionName}/${entryId}`);
  };

  return (
<TableContainer>
  <Table>
    <TableHead>
      <TableRow>
        <StyledTableCell>User</StyledTableCell>
        <StyledTableCell>Edit</StyledTableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {entries.length > 0 ? (
        entries.map((entry, index) => {
          let org = Array.isArray(entry.organizations) ? entry.organizations.find(org => org.id === orgId) : null;
          return (
            <StripedTableRow key={entry.id} hover index={index}>
              <StyledTableCell>
              <Stack direction="row" spacing={2}>
                <Typography variant="subtitle1" component="div" sx={{ flexGrow: 1 }}>{entry.email}</Typography>
                <Chip 
                  icon={org ? (org.status === 'accepted' ? <CheckCircleIcon style={{ color: 'green' }} /> : <PendingIcon style={{ color: 'orange' }} />) : null}
                  label={org ? org.role : ''}
                />
                {/* <Chip label={org ? org.status : ''} /> */}
              </Stack>
              </StyledTableCell>
              <StyledTableCell>
                <Button onClick={(event) => { handleEditUser(entry.id)}} variant="text" color="primary">
                  <NavigateNextIcon />
                </Button>
              </StyledTableCell>
            </StripedTableRow>
          );
        })
      ) : (
        <TableRow>
          <TableCell colSpan={2} style={{ textAlign: "center" }}>
            No entries found
          </TableCell>
        </TableRow>
      )}
    </TableBody>
  </Table>
</TableContainer>

  );
}

export default ListOrganizationUsers;
