import React from 'react';
import { List, Drawer, ListItemButton, ListItemIcon, ListItemText, Divider, ListItem } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import FolderIcon from '@mui/icons-material/Folder';
import RateReviewIcon from '@mui/icons-material/RateReview';
import SettingsIcon from '@mui/icons-material/Settings';
import ChatIcon from '@mui/icons-material/Chat';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import BusinessIcon from '@mui/icons-material/Business';
import ViewInArIcon from '@mui/icons-material/ViewInAr';
import EngineeringIcon from '@mui/icons-material/Engineering';
import ImageIcon from '@mui/icons-material/Image';

export default function Sidebar({ handleNavigation, AccessList, currentOrgRole, userData }) {
  return (
    <Drawer variant="permanent" sx={{ [`& .MuiDrawer-paper`]: { mt: '64px', zIndex: 1 } }}>
      <List>
        <ListItemButton onClick={() => handleNavigation('/dashboard')}>
          <ListItemIcon><HomeIcon /></ListItemIcon>
          <ListItemText primary="Home" />
        </ListItemButton>
        <ListItemButton onClick={() => handleNavigation('/account')}>
          <ListItemIcon><AccountBoxIcon /></ListItemIcon>
          <ListItemText primary="Account" />
        </ListItemButton>

        {AccessList.Everyone.includes(currentOrgRole) && (
          <ListItemButton onClick={() => handleNavigation('/chats')}>
            <ListItemIcon><ChatIcon /></ListItemIcon>
            <ListItemText primary="Chats" />
          </ListItemButton>
        )}

        {AccessList.Editors.includes(currentOrgRole) && (
          <>
            <Divider />
            <ListItem><ListItemText primary="Organization" /></ListItem>
          </>
        )}

        {AccessList.Owners.includes(currentOrgRole) && (
          <ListItemButton onClick={() => handleNavigation(`/organizations/${userData.currentOrganization}/experts`)}>
            <ListItemIcon><EngineeringIcon /></ListItemIcon>
            <ListItemText primary="Experts" />
          </ListItemButton>
        )}

        {AccessList.Editors.includes(currentOrgRole) && (
          <ListItemButton onClick={() => handleNavigation(`/organizations/${userData.currentOrganization}/finetuning`)}>
            <ListItemIcon><RateReviewIcon /></ListItemIcon>
            <ListItemText primary="Finetuning" />
          </ListItemButton>
        )}

        {AccessList.Editors.includes(currentOrgRole) && (
          <ListItemButton onClick={() => handleNavigation(`/organizations/${userData.currentOrganization}/files`)}>
            <ListItemIcon><FolderIcon /></ListItemIcon>
            <ListItemText primary="Files" />
          </ListItemButton>
        )}

        {AccessList.Editors.includes(currentOrgRole) && (
          <ListItemButton onClick={() => handleNavigation(`/organizations/${userData.currentOrganization}/images`)}>
            <ListItemIcon><ImageIcon /></ListItemIcon>
            <ListItemText primary="Images" />
          </ListItemButton>
        )}

        {AccessList.Editors.includes(currentOrgRole) && (
          <ListItemButton onClick={() => handleNavigation(`/organizations/${userData.currentOrganization}/objects`)}>
            <ListItemIcon><ViewInArIcon /></ListItemIcon>
            <ListItemText primary="Objects" />
          </ListItemButton>
        )}

        {AccessList.Owners.includes(currentOrgRole) && (
          <ListItemButton onClick={() => handleNavigation(`/organizations/${userData.currentOrganization}/users`)}>
            <ListItemIcon><SupervisorAccountIcon /></ListItemIcon>
            <ListItemText primary="Users" />
          </ListItemButton>
        )}

        {AccessList.Owners.includes(currentOrgRole) && (
          <ListItemButton onClick={() => handleNavigation('/settings')}>
            <ListItemIcon><SettingsIcon /></ListItemIcon>
            <ListItemText primary="Settings" />
          </ListItemButton>
        )}

        {userData?.superUser && (
          <>
            <Divider />
            <ListItem><ListItemText primary="Admin" /></ListItem>

            <ListItemButton onClick={() => handleNavigation('/organizations')}>
              <ListItemIcon><BusinessIcon /></ListItemIcon>
              <ListItemText primary="Organizations" />
            </ListItemButton>
          </>
        )}

      </List>
    </Drawer>
  );
}