import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { doc, onSnapshot, updateDoc } from 'firebase/firestore';
import { db } from '../firebase';
import { Box, Container, Grid, TextField, Button, CircularProgress, Typography, Paper } from '@mui/material';
import { getObjects, getTags } from '../Utilities/firestoreConnector';
import { addVectors, deleteFileVectors, deleteNamespace, getResource, updateResource } from '../Utilities/apiConnector';
import ResourceTable from '../Components/ResourceTable';
import ExpertConfig from '../Components/EpertConfig';

import { eventManager } from '../Utilities/eventManager';
import { useError } from '../Components/contexts/ErrorContext';
import { useNotification } from '../Components/contexts/NotificationContext';

export default function Expert() {
  const { orgId, expertId } = useParams();
  const [data, setData] = useState({});
  const [editedData, setEditedData] = useState({});
  const [objects, setObjects] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [selectedObjects, setSelectedObjects] = useState([]);
  const [selectedFinetunes, setSelectedFinetunes] = useState([]);
  const [resources, setResources] = useState([]);
  const [saving, setSaving] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const { showError, hasError } = useError();
  const { addNotification } = useNotification();

  useEffect(() => {
    const handleError = (error) => { showError(error); };
    eventManager.subscribe('api-error', handleError);
    return () => { eventManager.unsubscribe('api-error', handleError); };
  }, []);

  useEffect(() => {
    const unsubscribe = loadObject();
    return () => unsubscribe();
  }, [refresh]);

  const loadObject = () => {
    if (!expertId) { console.error('Undefined ID or docId'); return; }

    setObjects(getObjects(orgId));

    const fileDocRef = doc(db, 'experts', expertId);
    const unsubscribe = onSnapshot(fileDocRef, (docSnapshot) => {
      if (docSnapshot.exists()) {
        const data = docSnapshot.data();
        setData(data);
        setEditedData(data);

        setSelectedFiles(data.resources?.files ?? []);
        setSelectedObjects(data.resources?.objects ?? []);
        setSelectedFinetunes(data.resources?.finetunes ?? []);
      } else {
        console.error('Document does not exist');
      }
    });

    return unsubscribe;
  };

  const handleSave = async (update) => {
    await updateResource('expert', update, expertId);
    setRefresh(!refresh);
    // if (expertId) {
    //   const fileDocRef = doc(db, 'experts', expertId);
    //   await updateDoc(fileDocRef, update);
    //   console.log("Data saved");
    // } else {
    //   console.error('Undefined ID');
    // }
  };

  const handleResourcesSelected = async (s) => {
    setSaving(true);
  
    // Retrieve the current state of resources
    const currentResources = data.resources || {};
  
    // Merge the new selection with existing resources
    const updatedResources = {
      ...currentResources,
      [s.name]: s.selection
    };
  
    const updateData = { ...data, resources: updatedResources };
    await updateResource('expert', updateData, expertId);
  
    if (hasError.current) return;
  
    const expert = (await getResource('expert', expertId)).data;
  
    const allFiles = expert?.resources?.files;
    const allObjects = expert?.resources?.objects;
  
    const allTagsPromises = allObjects ? allObjects.map(async obj => await getTags(obj)) : [];
    const allTagsArrays = await Promise.all(allTagsPromises);
    const allTags = allTagsArrays.flat();
    const allTagIds = allTags.map(tag => tag.id);
  
    if (expert?.externalRef?.openai) {
      console.log(expert?.externalRef?.openai);
    } else {
      if (allFiles && s.name != 'finetunes') {
        await updateVDB([...allFiles, ...allTagIds]);
      }
    }
  
    setSaving(false);
  };
  

  const updateVDB = async (s) => {
    console.log("update vdb");
    await deleteNamespace(data.vdb ?? "pinecone", expertId);

    if (hasError.current) return;

    await Promise.all(s.map(async (item) => {
      await addVectors(item, data.vdb ?? "pinecone", expertId);
    }));
  };

  const reloadExpert = async () => {
    setSaving(true);

    const expert = (await getResource('expert', expertId)).data;

    if (hasError.current) return;

    const allFiles = expert?.resources?.files;
    const allObjects = expert?.resources?.objects;
    const allTagsPromises = allObjects ? allObjects.map(async obj => await getTags(obj)) : [];
    const allTagsArrays = await Promise.all(allTagsPromises);
    const allTags = allTagsArrays.flat();
    const allTagIds = allTags.map(tag => tag.id);

    if (allFiles) {
      await updateVDB([...allFiles, ...allTagIds])
    } else {
      await updateVDB([...allTagIds])
    }

    setSaving(false);
  };

  return (
    <Container sx={{ p: 1 }}>
      <Grid container spacing={3}>
        <Grid item xs={12}> <ExpertConfig data={data} onUpdate={handleSave} onReload={reloadExpert} /></Grid>
      </Grid>
      {saving ?
        <Grid container spacing={3} sx={{ display: 'flex', justifyContent: 'center' }}>
          <Paper elevation={3} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '40vh', height: '5vh', m: 5, p: 1 }}>
            <Typography variant="h6" component="span" sx={{ mr: 2 }}>
              Saving...
            </Typography>
            <CircularProgress />
          </Paper>
        </Grid>
        :
        <Paper sx={{ p: 2, mb: 2 }}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <ResourceTable collectionName="files" header="Files" fieldName="fileName" onSelect={(selection) => handleResourcesSelected({ name: "files", selection })} selected={selectedFiles} filter={data?.vdb === 'openai' ? ['externalRef.openai'] : []} />
            </Grid>
            <Grid item xs={12}>
              <ResourceTable collectionName="objects" header="3D Objects" fieldName="fileName" onSelect={(selection) => handleResourcesSelected({ name: "objects", selection })} selected={selectedObjects} selectionType="radio" />
            </Grid>
            <Grid item xs={12}>
              <ResourceTable collectionName="finetunes" header="Finetunes" fieldName="name" onSelect={(selection) => handleResourcesSelected({ name: "finetunes", selection })} selected={selectedFinetunes} />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2">External Ref: {JSON.stringify(data?.externalRef)}</Typography>
            </Grid>
          </Grid>
        </Paper>
      }
    </Container>
  );
}
