import React, { useState, useEffect, useContext } from 'react';
import { doc, getDoc } from "firebase/firestore";
import { UserContext } from '../UserProvider';
import { db } from '../firebase';
import { FormControl, FormHelperText, Select, MenuItem, CircularProgress } from '@mui/material';

export default function OrganizationSelector() {
  const { user, userData, updateUser } = useContext(UserContext);
  const [orgData, setOrgData] = useState([]);
  const [currentOrg, setCurrentOrg] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchOrgData = async () => {
      const data = await Promise.all(userData.organizations.map(async (org) => {

        if (org.status === "pending") return {};

        const docRef = doc(db, "organizations", org.id);
        const docSnap = await getDoc(docRef);
        
        if (org.id === userData.currentOrganization) setCurrentOrg(org);        

        return {
          id: org,
          name: docSnap.exists() ? docSnap.data().name : ''
        };
      }));

      setOrgData(data);
      setIsLoading(false);
    };
    
    if (userData && userData.organizations) {
      fetchOrgData();
    } else {
      setIsLoading(false);
    }
  }, [userData]);

  const handleChange = (e) => {
    try {
      const orgId = e.target.value;
      updateUser({ ...userData, currentOrganization: orgId.id });
    } catch (error) {
      console.error("Error updating document: ", error);
    }
  };

  if (isLoading) {
    return <CircularProgress />;
  }

  return (
    <FormControl fullWidth>
      <FormHelperText>Select Organization</FormHelperText>
      <Select
        labelId="organization-select-label"
        id="organization-select"
        value={currentOrg}
        onChange={handleChange}
      >
        {orgData ? 
          orgData.map((org, index) => (
            <MenuItem value={org.id} key={index}>{org.name}</MenuItem>
          ))
          : <MenuItem disabled>No organizations available</MenuItem>
        }
      </Select>
    </FormControl>
  );
}