import { useState, useEffect, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, TextField, Typography } from '@mui/material';
import { Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import { getChats, deleteMessages, deleteConversation, deleteChat } from '../Utilities/firestoreConnector';
import { findResources, findConversations, getResources, getResourcesURI } from '../Utilities/apiConnector';
import { UserContext } from '../UserProvider';

import { eventManager } from '../Utilities/eventManager';
import { useError } from '../Components/contexts/ErrorContext';
import { useNotification } from '../Components/contexts/NotificationContext';

export default function ChatTable() {
  const { user, userData } = useContext(UserContext);
  const [chats, setChats] = useState([]);
  const [selectedExpertId, setSelectedExpertId] = useState('');
  const [expertNames, setExpertNames] = useState({});
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();
  const { showError, hasError } = useError();
  const { addNotification } = useNotification();

  useEffect(() => {
    const handleError = (error) => { showError(error); };
    eventManager.subscribe('api-error', handleError);
    return () => { eventManager.unsubscribe('api-error', handleError); };
  }, []);

  const fetchData = async () => {
    const experts = await findResources('expert', 'owner', userData.currentOrganization);

    if (hasError.current) return;

    const expertMap = experts.data.reduce((map, expert) => {
      map[expert.id] = expert.name;
      return map;
    }, {});
    setExpertNames(expertMap);

    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  // useEffect(() => { loadfetchDataData(); }, [user]);

  const handleDelete = async (id) => {
    await deleteChat(id);
    fetchData();
  };

  const handleExpertChange = async (event) => {
    setSelectedExpertId(event.target.value);
    // const chats = await getResourcesURI(`conversations/${user.uid}/list/by-expert/${event.target.value}`);
    const chats = await findConversations('expert', event.target.value);
    console.log(chats);
    if (chats?.data) setChats(chats.data);
  };

  const handleEdit = (id) => { navigate(`/chats/${id}`); };

  if (loading) { return <>Loading...</>; }

  return (
    <TableContainer>
      <FormControl sx={{ mb: 2 }} fullWidth>
        <InputLabel id="expert-select-label">Expert</InputLabel>
        <Select labelId="expert-select-label" id="expert-select" value={selectedExpertId} label="Expert" onChange={handleExpertChange} >
          {Object.entries(expertNames).map(([id, name]) => (<MenuItem key={id} value={id}>{name}</MenuItem>))}
        </Select>
      </FormControl>

      <Table size={"small"}>
        <TableHead>
          <TableRow>
            <TableCell>Chat</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {chats.map((chat, index) => (
            <TableRow key={index}>
              <TableCell>
                <Typography variant="h6">{chat?.name || "New chat"}, {expertNames[chat.id] || ''}</Typography>
                <Typography variant="body2">{chat.id || ''}</Typography>
              </TableCell>
              <TableCell>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => handleEdit(chat.id)}
                  style={{ marginRight: '10px' }}
                >
                  Open
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => handleDelete(chat.id)}
                >
                  Delete
                </Button>

              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}