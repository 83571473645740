import React, { useState, useEffect, useContext } from 'react';
import FileUploader from '../Components/FileUploader';
import FileList from '../Components/FileList';
import { UserContext } from '../UserProvider';
import { Grid, Stack, Table, TableContainer } from '@mui/material';

function Files() {
  const { user, userData, updateUser } = useContext(UserContext);
  const [fileURL, setFileURL] = useState(null);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [fileUploadTrigger, setFileUploadTrigger] = useState(0);

  const handleFileSelection = (files) => {
    setFileURL(files[0]?.url);
    setSelectedFiles(files);
  };

  return (
    <TableContainer>
      {userData && userData.currentOrganization && (
        <Stack>
          <FileList onSelectFiles={handleFileSelection} category={'organizations'} id={userData?.currentOrganization} type={"file"} collectionName={"images"} refreshKey={fileUploadTrigger} />
          <FileUploader owner={userData?.currentOrganization} type={"file"} />
        </Stack>
      )}
    </TableContainer>
  );
}

export default Files;
