import React, { useState } from 'react';
import { TextField, Button, CircularProgress } from '@mui/material';
import { styled } from '@mui/system';

const StyledForm = styled('form')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: theme.spacing(1),
  borderTop: `1px solid ${theme.palette.divider}`,
}));

const StyledTextField = styled(TextField)({
  flex: 1,
  marginRight: '10px',
});

function ChatInput({ sendMessage, loading }) {
  const [message, setMessage] = useState('');

  const onSubmit = (e) => {
    e.preventDefault();
    if (message.trim() !== '' && !loading) {
      sendMessage(message);
      setMessage('');
    }
  };

  return (
    <StyledForm onSubmit={onSubmit}>
      <StyledTextField
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        placeholder="Type a message..."
        variant="outlined"
        size="small"
      />
      <Button variant="contained" color="primary" type="submit" disabled={loading}>
        {loading ? <CircularProgress size={24} color="inherit" /> : 'Send'}
      </Button>
    </StyledForm>
  );
}

export default ChatInput;
