import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Typography, Container, TextField, Button, Grid, Stack, Paper, Chip } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import AddUser from '../../Components/AddUser';
import ListUsers from '../../Components/ListOrganizationUsers';
import { getCoreResource, updateOrganization, getOpenAIKey, setOpenAIKey, deleteOpenAIKey } from '../../Utilities/apiConnector';

export default function Organisation() {
  const { orgId } = useParams();
  const [org, setOrg] = useState({});
  const [key, setKey] = useState('');
  const [keySaved, setKeySaved] = useState(false);
  const [orgUpdate, setOrgUpdate] = useState({});
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    const getOrganization = async () => {
      const organization = (await getCoreResource('organization', orgId)).data;
      const keyStatus = (await getOpenAIKey(orgId)).data;
      setKeySaved(keyStatus);
      setOrg(organization);
      setOrgUpdate(organization);
    }

    getOrganization();
  }, [orgId, refresh]);

  const handleUpdate = (name, value) => {
    const update = { ...orgUpdate, [name]: value };
    setOrgUpdate(update);
  };

  const handleSetOpenaiKey = async (e) => {
    const key = e.target.value;
    setKey(key);
  };

  const handleDeleteOpenaiKey = async (e) => {
    if (!keySaved) return;
    deleteOpenAIKey(orgId);
    setRefresh(prev => !prev);
  };

  const handleSave = async () => {
    await updateOrganization(orgUpdate, orgId);
    if (key) await setOpenAIKey(orgId, key);
    setKey('');
    setRefresh(prev => !prev);
  };

  return (
    <Container>
      <Paper sx={{ p: 1, mb: 1 }}>
        <Stack spacing={1}>
          <Typography variant="h4" component="h1">{org?.name} details</Typography>
          <TextField value={orgUpdate?.name || ''} onChange={(e) => handleUpdate('name', e.target.value)} label="Organization Name" size="small" />
          <Stack direction="row">
            <Chip sx={{ m: 1 }} size="small" icon={keySaved ? <CheckCircleIcon color="success" /> : <CancelIcon color="error" />} label={keySaved ? "Saved" : "Missing"} variant="contained" />
            <TextField fullWidth value={key || ''} onChange={handleSetOpenaiKey} label="OpenAI Key" size="small" />
            <Button sx={{ ml: 1 }} variant='contained' color="error" disabled={!keySaved} onClick={handleDeleteOpenaiKey}>DELETE</Button>
          </Stack>
          <Button variant='contained' onClick={handleSave}>SAVE</Button>
        </Stack>
      </Paper>
      <ListUsers collectionName="users" filterField="organisations" filterValue={orgId} />
      <AddUser orgId={orgId} />
    </Container >
  );
}