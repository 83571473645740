import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography, Paper, Grid, ImageList, ImageListItem, ImageListItemBar, Checkbox, IconButton } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import EditIcon from '@mui/icons-material/Edit';
import { DataGrid } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
import { getDownloadURL, ref, deleteObject } from 'firebase/storage';
import { collection, query, where, getDocs, doc, deleteDoc, onSnapshot, getResource } from 'firebase/firestore';
import { db, storage } from '../firebase';
import { deleteResource } from '../Utilities/apiConnector';

export default function ImagesList({ onSelectFiles, category, type, id, refreshKey, collectionName }) {
  const { orgId } = useParams();

  const [files, setFiles] = useState([]);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = loadFiles();
    return () => unsubscribe();
  }, [category, id, refreshKey, selectedFiles]);  

  const loadFiles = () => {
    if (!id) {
      console.error('Undefined ID');
      return;
    }
  
    const fileCollection = collection(db, collectionName);
    const fileQuery = query(fileCollection, where('owner', '==', id));
  
    const unsubscribe = onSnapshot(fileQuery, (querySnapshot) => {
      const filePromises = querySnapshot.docs.map(async (doc) => {
          const data = doc.data();
          // const fileName = data.fileName.split('.')[0];
          const fileRef = data.fileRef;
          const storageRef = ref(storage, fileRef);
          const url = await getDownloadURL(storageRef);
  
          return { id: doc.id, fileName: data.fileName, fileURL: url, status: data.status };
      });
  
      Promise.all(filePromises).then(fileList => {
          setFiles(fileList);
      });
    });
  
    // Clean up listener on unmount
    return unsubscribe;
  };
  

  const handleButtonClick = (row) => {
    navigate(`/organizations/${orgId}/images/${row.id}`);
    // Handle your logic here
  };
  

const handleDeleteClick = () => {
  setOpenDeleteDialog(true);
};

const handleDeleteConfirm = async () => {

  const promises = selectedFiles.map(async (file) => {
    const fileName = file.fileName.split('.')[0];
    const storageRef = ref(storage, `${category}/${id}/${collectionName}/${fileName}/${file.fileName}`);
    try {
      // await deleteObject(storageRef);
      await deleteResource('image', file.id);
    } catch (error) {
      if (error.message.includes("object does not exist")) {
        console.log(`File ${file.fileName} does not exist, skipping deletion.`);
      } else {
        // If it's another error, rethrow it
        throw error;
      }
    }
  });

  await Promise.all(promises);  // Wait for all deletions to complete
  setOpenDeleteDialog(false);
  setSelectedFiles([]);
  onSelectFiles(selectedFiles);
  // loadFiles();  // Reload the files
  };

  const handleDeleteCancel = () => {
    setOpenDeleteDialog(false);
  };

  // const handleSelectionChange = (newSelection) => {
  //   // Filter out the IDs of files with a 'pending' status
  //   const selectableIds = newSelection.filter((id) =>
  //     files.find((file) => file.id === id && file.status !== 'pending')
  //   );
  
  //   // Find the selected files based on the filtered IDs
  //   const selectedFiles = files.filter((file) => selectableIds.includes(file.id));
    
  //   setSelectedFiles(selectedFiles);
  //   onSelectFiles(selectedFiles); // Call the callback with selected files
  // };

  const toggleSelection = (file) => {
    const isSelected = selectedFiles.some(selectedFile => selectedFile.id === file.id);
    if (isSelected) {
      setSelectedFiles(selectedFiles.filter(selectedFile => selectedFile.id !== file.id));
    } else {
      setSelectedFiles([...selectedFiles, file]);
    }
  };

  return (
    <Paper>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <Typography variant="h4" component="h1" sx={{ p: 2 }}>Images List</Typography>
        { selectedFiles.length > 0 && <Button variant="contained" color="secondary" startIcon={<DeleteIcon />} onClick={handleDeleteClick}>Delete</Button> }
      </Box>
      <Box sx={{ overflowY: 'auto' }}>
      <ImageList variant="masonry" cols={4} gap={8} sx={{ m: 1 }}>
        {files.map((file) => (
          <ImageListItem key={file.id} sx={{ position: 'relative', overflow: 'hidden' }}>
            <img
              src={file.fileURL}
              alt={file.fileName}
              loading="lazy"
              style={{ objectFit: 'cover', cursor: 'pointer', transition: 'transform 0.3s ease-in-out' }}
              onMouseOver={(e) => { e.currentTarget.style.transform = 'scale(1.05)'; }}
              onMouseOut={(e) => { e.currentTarget.style.transform = 'scale(1)'; }}
              onClick={() => toggleSelection(file)}
            />
            <Box sx={{ position: 'absolute', bottom: 0, right: 0, m: 1 }}>
              <IconButton
                sx={{ backgroundColor: 'primary.main', '&:hover': { backgroundColor: 'primary.dark' }, color: 'white' }}
                onClick={(e) => { e.stopPropagation(); handleButtonClick(file); }}
              >
                <EditIcon />
              </IconButton>
            </Box>
            {selectedFiles.some(selectedFile => selectedFile.id === file.id) && (
              <Box sx={{ position: 'absolute', top: 0, right: 0, m: 0.5 }}>
                <CheckCircleIcon sx={{ color: 'white' }} />
              </Box>
            )}
          </ImageListItem>
        ))}
      </ImageList>
    </Box>
      <Dialog open={openDeleteDialog} onClose={handleDeleteCancel}>
        <DialogTitle>Delete File</DialogTitle>
        <DialogContent>
          <DialogContentText>Are you sure you want to delete these {selectedFiles.length} file(s)?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteCancel} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteConfirm} color="primary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
}
