import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import ListUsers from '../../Components/ListOrganizationUsers';
import AddUser from '../../Components/AddUser';
import { Typography, Container } from '@mui/material';
import { getCoreResource } from '../../Utilities/apiConnector';

function User() {
  const { orgId } = useParams();
  const [org, setOrg] = useState();

  useEffect(() => {
    const getOrganization = async () => {
      const organization = (await getCoreResource('organization', orgId)).data;
      setOrg(organization);
    }

    getOrganization();
  }, [orgId]);

  return (
    <>
      <Typography variant="h4" component="h1">
        {org?.name} Users
      </Typography>
      <Container>
        <ListUsers collectionName="users" filterField="organisations" filterValue={orgId} />
      </Container>
      <AddUser orgId={orgId} />
    </>
  );
}

export default User;