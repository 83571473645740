import React, { createContext } from 'react';

export const ConfigContext = createContext(null);

const Roles = {
  Reader : 'reader',
  Editor : 'editor',
  Owner : 'owner'
};

const AccessList = {
  Owners : [Roles.Owner],
  Editors : [Roles.Editor, Roles.Owner],
  Everyone : [Roles.Reader, Roles.Editor, Roles.Owner],
}

const ConfigProvider = ({ children }) => {


  return (
    <ConfigContext.Provider value={{ AccessList, Roles }}>
      {children}
    </ConfigContext.Provider>
  );
};

export default ConfigProvider;
