import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, TextField, Typography } from '@mui/material';
import { FormControl, FormHelperText, Select, MenuItem, CircularProgress, Grid, Box, Container, Checkbox } from '@mui/material';

import { doc, addDoc, setDoc, getDoc, arrayUnion, updateDoc, deleteDoc, collection, serverTimestamp, query, where, getDocs, onSnapshot, orderBy } from "firebase/firestore";
import { db } from '../firebase';
import { addFileVectors, deleteFileVectors, deleteNamespace, setResource, deleteResource } from '../Utilities/apiConnector';
import { deleteExpertChats, deleteExpert } from '../Utilities/firestoreConnector';

export default function ExpertsTable() {
  const { orgId } = useParams();
  const [searchText, setSearchText] = useState('');
  const [experts, setExperts] = useState([]);
  const [vdb, setVDB] = useState(["pinecone", "immersion", "openai"]);
  // const [openaiExpert, setOpenaiExpert] = useState(false);
  const [currentConfiguration, setCurrentConfiguration] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = loadData();
    return () => unsubscribe();
  }, []);

  const loadData = () => {
    const expertsCollection = collection(db, 'experts');
    const fileQuery = query(expertsCollection, where('owner', '==', orgId));

    const unsubscribe = onSnapshot(fileQuery, (querySnapshot) => {
      const filePromises = querySnapshot.docs.map(async (doc) => {
        const data = doc.data();
        return { id: doc.id, ...data };  // Replaced doc.uid with doc.id
      });

      Promise.all(filePromises).then(list => { setExperts(list); });
    });

    return unsubscribe;
  };

  const handleCreate = async () => {
    try {
      const newExpert = {
        owner: orgId,
        name: 'New Name',
        config: { model: 'gpt-4o' },
        vdb: currentConfiguration
      };

      const resp = await setResource('expert', newExpert);
      navigate(`/organizations/${orgId}/experts/${resp.data.data.id}`);
    } catch (error) {
      console.error('Error creating new expert:', error);
    }
  };

  const handleDelete = async (id) => {
    try {
      const resp = await deleteResource('expert', id);
      console.log(resp);
      // await deleteExpertChats(id);
      // await deleteNamespace(id);
      // await deleteExpert(id);
      // console.log("Expert deleted");
    } catch (error) {
      console.error('Error deleting expert:', error);
    }
  };

  const handleEdit = (id) => {
    navigate(`/organizations/${orgId}/experts/${id}`);
  };

  const handleSearchChange = (event) => {
    setSearchText(event.target.value);
  };

  const handleConfiguration = (e) => {
    setCurrentConfiguration(e.target.value);
  };

  const filteredExperts = experts.filter(expert => expert.name.toLowerCase().includes(searchText.toLowerCase()));

  return (
    <TableContainer>
      <Paper>
        <Container>
          <Box display="flex" alignItems="center">
            {/* <Box sx={{ mr: 2 }}>
            <Checkbox onChange={e => setOpenaiExpert(e.target.checked)}/>OpenAI Expert
          </Box> */}
            <Box flexGrow={1} sx={{ mr: 2 }}>
              <FormControl fullWidth>
                <FormHelperText>Select Configuration</FormHelperText>
                <Select labelId="config-select-label" id="config-select" value={currentConfiguration} onChange={handleConfiguration}>
                  {vdb ?
                    vdb.map((vdb, index) => (
                      <MenuItem value={vdb} key={index}>{vdb}</MenuItem>
                    ))
                    : <MenuItem disabled>No vdb available</MenuItem>
                  }
                </Select>
              </FormControl>
              <TextField
                variant="outlined"
                label="Search Experts"
                value={searchText}
                onChange={handleSearchChange}
                fullWidth
                margin="normal"
              />
            </Box>
            <Box>
              <Button variant="contained" color="primary" onClick={handleCreate} disabled={currentConfiguration === null || currentConfiguration === ""}>Create Expert</Button>
            </Box>
          </Box>
        </Container>
        <Table size={"small"}>
          <TableHead>
            <TableRow>
              <TableCell>Expert</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
          {filteredExperts.map((expert, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <Typography variant="h6">{expert.name}, {expert.vdb ?? "---"}</Typography>
                    <Typography variant="body2">{expert.id || ''}, {expert?.config?.model || 'gpt-4o'}</Typography>
                  </TableCell>
                  <TableCell>
                    <Button variant="contained" color="primary" onClick={() => handleEdit(expert.id)} style={{ marginRight: '10px' }}>
                      Edit
                    </Button>
                    <Button variant="contained" color="secondary" onClick={() => handleDelete(expert.id)}>
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
        </Table>
      </Paper>
    </TableContainer>
  );
}