import { Vector3, MeshStandardMaterial, CubeTextureLoader, Box3, Sphere } from 'three';
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';

//============================================================================
// Object Lodaer
//============================================================================

export const load3DModel = ({ name, url }) => {
  return new Promise((resolve, reject) => {
    const fileExtension = name.split('.').pop().toLowerCase();
    let selectedLoader;

    switch (fileExtension) {
      case 'fbx': selectedLoader = new FBXLoader(); break;
      case 'obj': selectedLoader = new OBJLoader(); break;
      case 'glb':
      case 'gltf': selectedLoader = new GLTFLoader(); break;
      default: reject('Unsupported file format'); return;
    }

    selectedLoader.load(url, (loadedObject) => {
      let object3D;
      switch (fileExtension) {
        case 'fbx':
        case 'obj': object3D = loadedObject; break;
        case 'glb':
        case 'gltf': object3D = loadedObject.scene; break;
        default: reject('Unknown file format'); return;
      }

      resolve(object3D);
    });
  });
};

//============================================================================
// Material Setter
//============================================================================

export const updateObject3DMaterial = (object3D, EnvMap, metallic, roughness, replaceMaterial = null) => {
  
    object3D.traverse((node) => {
      if (node.isMesh && node.material) {
        const propertiesToCopy = ['color', 'map', 'emissive', 'emissiveMap', 'emissiveIntensity', 'alphaMap', 'alphaTest', 'transparent', 'opacity'];
        const newMaterial = new MeshStandardMaterial();
        
        propertiesToCopy.forEach(prop => {
          if (node.material.hasOwnProperty(prop) && newMaterial.hasOwnProperty(prop)) {
              newMaterial[prop] = node.material[prop];
          }
        });
  
        newMaterial.envMap = EnvMap;
        newMaterial.metalness = metallic;
        newMaterial.roughness = roughness;
        node.material = replaceMaterial ? replaceMaterial : newMaterial;
      }
    });
  };
  
//============================================================================
// EnvMap Loader
//============================================================================

export const loadEnvironmentMap = (path) => {
    const cubeTextureLoader = new CubeTextureLoader();
    cubeTextureLoader.setPath(path);
    const EnvMap = cubeTextureLoader.load(['px.png', 'nx.png', 'py.png', 'ny.png', 'pz.png', 'nz.png']);
    return EnvMap;
  };

//============================================================================
// Helpers
//============================================================================

export const worldToScreenPosition = (position, camera, canvasSize) => {
  const vector = position.clone(); // Make a clone if you don't want to modify the original vector

  vector.project(camera); // Project the vector to the camera

  const halfWidth = canvasSize.width / 2;
  const halfHeight = canvasSize.height / 2;

  vector.x = (vector.x * halfWidth) + halfWidth;
  vector.y = -(vector.y * halfHeight) + halfHeight;

  return { x: vector.x, y: vector.y };
};

export const lerp = (start, end, t) => {
  return start + (end - start) * t;
};

// Smoothly moves the camera to a new position and target
export const smoothCameraTransition = (camera, controls, newPosition, newTarget, duration) => {
  const startPosition = camera.position.clone();
  const startTarget = controls.target.clone();

  let startTime;

  const animate = (time) => {
  if (!startTime) startTime = time;

  const elapsed = time - startTime;
  const t = Math.min(elapsed / duration, 1);

  camera.position.set(
    lerp(startPosition.x, newPosition.x, t),
    lerp(startPosition.y, newPosition.y, t),
    lerp(startPosition.z, newPosition.z, t)
  );

  controls.target.set(
    lerp(startTarget.x, newTarget.x, t),
    lerp(startTarget.y, newTarget.y, t),
    lerp(startTarget.z, newTarget.z, t)
  );

  controls.update();

  if (t < 1) {
    requestAnimationFrame(animate);
  }
  };

  requestAnimationFrame(animate);
};

// Calculate the bounding box from an array of Vector3 points
export const calculateBoundingBox = (points) => {
  const box = new Box3();
  box.setFromPoints(points);
  return box;
};

// Calculate the bounding sphere from an array of Vector3 points
export const calculateBoundingSphere = (points) => {
  const box = new Box3().setFromPoints(points);
  const sphere = new Sphere();
  box.getBoundingSphere(sphere);
  return sphere;
};

export const extractPointsFromBoundingBox = (boundingBox) => {
  const { min, max } = boundingBox;

  return [
    new Vector3(min.x, min.y, min.z),
    new Vector3(min.x, min.y, max.z),
    new Vector3(min.x, max.y, min.z),
    new Vector3(min.x, max.y, max.z),
    new Vector3(max.x, min.y, min.z),
    new Vector3(max.x, min.y, max.z),
    new Vector3(max.x, max.y, min.z),
    new Vector3(max.x, max.y, max.z),
  ];
};

// Calculate the optimal camera distance to fit all points in view
export const calculateBoxCameraDistance = (box, fov) => {
  const center = box.getCenter(new Vector3());
  const size = box.getSize(new Vector3());
  
  // Calculate the max dimension of the bounding box
  const maxDim = Math.max(size.x, size.y, size.z);
  
  // Convert the FOV to radians
  const fovInRadians = fov * (Math.PI / 180);
  
  // Calculate the camera distance based on the FOV and bounding box dimensions
  const distance = maxDim / (2 * Math.tan(fovInRadians / 2));
  
  return {
    distance,
    center,
  };
};

export const calculateSphereCameraDistance = (sphere, fov) => {
  const radius = sphere.radius;
  const fovInRadians = fov * (Math.PI / 180);
  const distance = radius / Math.sin(fovInRadians / 2);
  return {
    distance,
    center: sphere.center,
  };
};

// Use this function in your component where you have access to camera, controls, and points
export const adjustCameraToViewAllPoints = (points, camera, controls, fov, aspectRatio) => {

  // const box = calculateBoundingBox(points);
  // const { distance, center } = calculateBoxCameraDistance(box, fov, aspectRatio);

  const sphere = calculateBoundingSphere(points);
  const { distance, center } = calculateSphereCameraDistance(sphere, fov, aspectRatio);

  camera.position.set(center.x, center.y, center.z + distance);
  controls.target.copy(center);
  controls.update();
};

// Calculate the optimal camera position to fit all points in view
export const calculateOptimalCameraPosition = (box, fov, aspectRatio) => {
  const center = box.getCenter(new Vector3());
  const size = box.getSize(new Vector3());
  const maxDim = Math.max(size.x, size.y, size.z);
  const fovInRadians = fov * (Math.PI / 180);
  const distance = maxDim / (2 * Math.tan(fovInRadians / 2));

  // Adjust the camera position based on the aspect ratio
  const cameraZ = distance / Math.cos(Math.atan(aspectRatio));

  return {
    position: new Vector3(center.x, center.y, center.z + cameraZ),
    target: center,
  };
};

// Use this function in your component where you have access to camera, controls, and points
export const adjustCameraToPoints = (points, camera, controls, fov, aspectRatio) => {
  const box = calculateBoundingBox(points);
  const { position, target } = calculateOptimalCameraPosition(box, fov, aspectRatio);

  // Smoothly transition to the new camera position and target (optional)
  smoothCameraTransition(camera, controls, position, target, 2000);

  // Or directly set the new camera position and target
  // camera.position.copy(position);
  // controls.target.copy(target);
  // controls.update();
};