import React, { useContext } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import MainLayout from './MainLayout';
import Dashboard from './Pages/Dashboard';
import Account from './Pages/Account';
import Finetune from './Pages/Admin/Finetune';
import Finetunes from './Pages/Finetunes';
import File from './Pages/Admin/File';
import Files from './Pages/Files';
import Image from './Pages/Image';
import Images from './Pages/Images';
import Object from './Pages/Object';
import Objects from './Pages/Objects';
import Chat from './Pages/Chat';
import Chats from './Pages/Chats';
import Login from './Pages/Login';
import Organization from './Pages/Admin/Organization';
import Settings from './Pages/Settings';
import User from './Pages/Admin/User';
import Users from './Pages/Admin/Users';
import Expert from './Pages/Expert';
import Experts from './Pages/Experts';
import Organizations from './Pages/Organizations';
import Activation from './Pages/Activation';
import ProtectedComponent from './ProtectedComponent';
import { UserContext } from './UserProvider';
import { ConfigContext } from './ConfigProvider';

import { ErrorProvider } from './Components/contexts/ErrorContext';
import { NotificationProvider } from './Components/contexts/NotificationContext';

function App() {
  const { user, loading } = useContext(UserContext);
  const { AccessList } = useContext(ConfigContext);

  if (loading) {
    return <>Loading...</>;
  }

  return (
    <Router>
      {user ? (
        <ErrorProvider>
          <NotificationProvider>
            <MainLayout>
              <Routes>
                <Route path="/" element={<Dashboard />} />
                <Route path="dashboard" element={<Dashboard />} />
                <Route path="account" element={<Account />} />


                <Route path="chats" element={<ProtectedComponent roles={AccessList.Everyone}> <Chats /> </ProtectedComponent>} />
                <Route path="chats/:chatId" element={<ProtectedComponent roles={AccessList.Everyone}> <Chat /> </ProtectedComponent>} />

                <Route path="settings" element={<ProtectedComponent roles={AccessList.Owners}> <Settings /> </ProtectedComponent>} />

                <Route path="organizations/:orgId/experts" element={<ProtectedComponent roles={AccessList.Owners}><Experts /></ProtectedComponent>} />
                <Route path="organizations/:orgId/finetuning" element={<ProtectedComponent roles={AccessList.Editors}><Finetunes /></ProtectedComponent>} />
                <Route path="organizations/:orgId/files" element={<ProtectedComponent roles={AccessList.Editors}><Files /></ProtectedComponent>} />
                <Route path="organizations/:orgId/images" element={<ProtectedComponent roles={AccessList.Editors}><Images /></ProtectedComponent>} />
                <Route path="organizations/:orgId/objects" element={<ProtectedComponent roles={AccessList.Editors}><Objects /></ProtectedComponent>} />
                <Route path="organizations/:orgId/users" element={<ProtectedComponent roles={AccessList.Owners}><Users /></ProtectedComponent>} />

                <Route path="organizations/:orgId" element={<Organization />} />
                <Route path="organizations/:orgId/users/:userId" element={<User />} />
                <Route path="organizations/:orgId/experts/:expertId" element={<Expert />} />
                <Route path="organizations/:orgId/images/:imageid" element={<Image />} />
                <Route path="organizations/:orgId/files/:fileId" element={<File />} />
                <Route path="organizations/:orgId/finetune/:finetuneId" element={<Finetune />} />
                <Route path="organizations/:orgId/objects/:objectId" element={<Object />} />
                <Route path="organizations/:orgId/finetuning/:finetuneId" element={<Finetune />} />

                <Route path="organizations" element={<Organizations />} />

                <Route path="*" element={<Dashboard />} />
              </Routes>
            </MainLayout>
          </NotificationProvider>
        </ErrorProvider>
      ) : (
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/activate" element={<Activation />} />
          <Route path="*" element={<Login />} />
        </Routes>
      )}
    </Router>
  );
}

export default App;
