import React, { useState, useEffect, useContext } from 'react';
import { UserContext } from '../UserProvider';
import { FormControl, FormHelperText, Select, MenuItem, CircularProgress } from '@mui/material';
import { findResources } from '../Utilities/apiConnector';

export default function ExpertSelector({ orgId, onSelect }) {
  const [experts, setExperts] = useState([]);
  const [expert, setExpert] = useState(null);
  const [loading, setLoading] = useState(true);


  const fetchData = async () => {
    const list = await findResources('expert', 'owner', orgId);
    console.log(list);
    setExperts(list.data);
    if (list?.data?.length > 0) { setExpert(list.data[0].name); onSelect(list.data[0].id); }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleChange = (e) => {
    const selectedExpertName = e.target.value;
    const selectedExpert = experts.find(expert => expert.name === selectedExpertName);
    if (selectedExpert) {
      setExpert(selectedExpert.name);
      onSelect(selectedExpert.id);  // Pass the selected expert's ID to the parent component
    }
  };

  if (loading) { return <>Loading...</>; }

  return (
    <FormControl fullWidth>
      <FormHelperText>Select Expert</FormHelperText>
      <Select
        labelId="organization-select-label"
        id="organization-select"
        value={expert || ""}
        onChange={handleChange}
      >
        {experts && experts.length > 0 ?
          experts.map((expert, index) => (
            <MenuItem value={expert.name} key={index}>{expert.name}</MenuItem>
          ))
          : <MenuItem value="" disabled>No experts available</MenuItem>
        }
      </Select>
    </FormControl>
  );
}