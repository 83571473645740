import React, { useState, useEffect, useContext } from 'react';
import { UserContext } from '../UserProvider';
import { TextField, Container, Toolbar, Typography, Button, Box, Drawer, List, ListItem, ListItemIcon, ListItemText, Divider, Paper } from '@mui/material';
import OrganizationSelector from '../Components/OrganizationSelector';
import { generateApiKey, deleteApiKey, setAppPassword as setAppPass, deleteAppPassword} from '../Utilities/apiConnector';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { green, red, blue, grey } from '@mui/material/colors';

function Account() {
  const { user, userData, updateUser } = useContext(UserContext);
  const [ displayName, setDisplayName ] = useState('');
  const [ appPassword, setAppPassword ] = useState('');
  const [ newAppPassword, setNewAppPassword ] = useState('');

  useEffect(() => {
    if (userData) {
      setDisplayName(userData?.displayName || '');
      setAppPassword(userData?.appPassword || '');
    }
  }, [userData]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      updateUser({ ...userData, displayName: displayName})
    } catch (error) {
      console.error('Error signing in', error);
    }
  };

  const setAppPaswordHandler = async (e) => {
    e.preventDefault();
    await setAppPass(newAppPassword);
    setNewAppPassword('');
  }

  const deleteAppPaswordHandler = async () => {
    await deleteAppPassword();
    setNewAppPassword('');
  }

  return (
    <Container sx={{ p: 2, mb: 1 }}>
        <form onSubmit={handleSubmit}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="displayName"
            label="Display Name"
            name="displayName"
            autoFocus
            value={ displayName }
            onChange={(e) => setDisplayName(e.target.value)}
          />
          
          <Button type="submit" fullWidth variant="contained" color="primary" style={{ marginTop: '10px' }}>Save</Button>
        </form>

        <Box sx={{ p: 2, mb: 1 }}>
          <Divider />
        </Box>
        
        <Box sx={{ p: 2, mb: 1 }}>
          <OrganizationSelector />
        </Box>

        <Paper sx={{ p: 2, mb: 1 }}>
          <form onSubmit={setAppPaswordHandler}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="appPassword"
              label="App Password"
              name="appPassword"
              autoFocus
              value={ newAppPassword }
              onChange={(e) => setNewAppPassword(e.target.value)}
            />
            
            <Button type="submit" fullWidth variant="contained" color="primary" sx={{ mb: 1 }}>Save App Password</Button>
          </form>
          <Button onClick={deleteAppPaswordHandler} fullWidth variant="contained" color="secondary">Delete App Password</Button>

          { appPassword && <Box sx={{ p: 1, mt: 2, alignItems: 'center', justifyContent: 'center', display: 'flex' }}><CheckCircleIcon sx={{ color: green[500], mr: 2 }} /> App password is set.</Box> }
        </Paper>

    </Container>
  );

}

export default Account;